import React, {Component} from 'react';
import {Button, Upload, Icon} from 'antd';
import { string } from 'prop-types';

class FileUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			uploading: false,
		};
	}

	render() {
		const props = {
			multiple: this.props.multiple ? this.props.multiple : false,
			onRemove: file => {
				this.props.changeList(fileList => {
					const index = fileList.indexOf(file);
					const newFileList = fileList.slice();
					this.props.datosArchivo.archivo = undefined;
					
					newFileList.splice(index, 1);

					return newFileList;
				}, this.props.datosArchivo);
			},
			beforeUpload: file => {

				if(this.props.validacion && !this.props.validacion(file)) return false;
				this.props.changeList(fileList => {
					this.props.datosArchivo.archivo = file;
					if (fileList !== undefined)
						return [...fileList, file];
					
					

					return [file]; 
				}, this.props.datosArchivo);

				return false;
			},
			fileList: this.props.fileList,
			defaultFileList: this.props.defaultFileList,
		};

		const clip = typeof this.props.etiqueta === "string"?<Icon type="paper-clip" />:undefined
		
		return (
			<Upload {...props} accept={this.props.accept}
			                   disabled = {this.props.disabled} > 
					{clip} {this.props.etiqueta}
			</Upload>
		);
	}
}
FileUpload.defaultProps={etiqueta:'Adjuntar'}
export default FileUpload;

export function createFormData(json, jsonKey, arreglo) {
	const formData = new FormData();

	for (var i = 2; i < arreglo.length; i += 2) {
		const fileList = arreglo[i];

		fileList.forEach(file => {
			formData.append(arreglo[i + 1], file);
		});
	}

	formData.append(jsonKey, new Blob([JSON.stringify(json)], {type: "application/json"}))

	return formData;
}
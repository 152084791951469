import React from 'react';
import {Card, Row, Col, Checkbox as CheckboxANTD, Modal} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import RadioGroup, {Radio} from '../interfaz/Radio';
import Checkbox from '../interfaz/Checkbox';
import InputText from '../interfaz/InputText';
import Tiempo from '../interfaz/Tiempo';

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

const discapacidadOptions = [
    { value: 'F', label: 'Física o motora' },
    { value: 'I', label: 'Intelectual' },
    { value: 'M', label: 'Mental o psicosocial' },
    { value: 'S', label: 'Sensorial' },
    { value: 'O', label: 'Otra' },
];

class DatosOtros5 extends React.Component {

    constructor(props) {
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = { 
            cartaDeclaratoriaVisible: false
        };  
        
        this.handleDiscapacidadesSeleccionadasChange = this.handleDiscapacidadesSeleccionadasChange.bind(this);        
        
    }

    handleDiscapacidadesSeleccionadasChange = (value) => {        
        this.props.form.setFieldsValue({
            especifiqueDiscapacidad: null,
        });

        this.props.form.validateFields();
    }

    handleCartaDeclaratoriaClick = (e) => {
        e.preventDefault();
        this.setState({
            cartaDeclaratoriaVisible : true
        });
    }

    handleCartaDeclaratoriaClose = () => {
        this.setState({
            cartaDeclaratoriaVisible : false
        });
    }

    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_datos_otros_lbl_titulo} bordered={false}>                                                                                
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <Tiempo nombre="tiempoTraslado"
                                        label={etiquetas.datos_datos_otros_lbl_tiempoTraslado}
                                        format="HH:mm"
                                        formato="HH:mm" 
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        form={this.props.form}
                                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}/>          
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'tieneInternet'}
                                        name={'tieneInternet'}
                                        label={etiquetas.datos_datos_otros_lbl_tieneInternet}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox.Group nombre={'discapacidadesSeleccionadas'} 
                                            label={etiquetas.datos_datos_otros_lbl_discapacidadesSeleccionadas}                          
                                            form={this.props.form}                                 
                                            required={false} 
                                            msjRequerido={etiquetas.general_dato_requerido}
                                            mensajeValidacion={etiquetas.general_dato_requerido}
                                            rules={[{type: 'array'}]}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.handleDiscapacidadesSeleccionadasChange(value)}>
                                    <Row style={{'textAlign':'justify'}}>
                                        {
                                            discapacidadOptions &&
                                            discapacidadOptions.map((opcion, index) => (                                
                                                <Col span={8} key={index}>
                                                    <CheckboxANTD value={opcion.value}>{opcion.label}</CheckboxANTD>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                            </Checkbox.Group>                           
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="especifiqueDiscapacidad"                                       
                                        placeholder={etiquetas.datos_datos_otros_lbl_especifiqueDiscapacidad}
                                        form={this.props.form}      
                                        disabled={!(this.props.form.getFieldValue("discapacidadesSeleccionadas") && this.props.form.getFieldValue("discapacidadesSeleccionadas").includes('O'))}                                 
                                        required={this.props.form.getFieldValue("discapacidadesSeleccionadas") && this.props.form.getFieldValue("discapacidadesSeleccionadas").includes('O')}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={50}
                                        rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES 
                                            }]}/>     
                        </Col>
                    </Row>
                    <hr style={{margin: '0px'}} />
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox nombre="cartaDeclaratoria"
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        rules={[{validator : (rule, value, cb) => (value === true ? cb() : cb(true))}]}
                                        style={{ 'textAlign': 'justify' }}>
                                Acepto 
                                <a href="#!" onClick={this.handleCartaDeclaratoriaClick}> carta declaratoria</a> 
                                .
                            </Checkbox>                                   
                        </Col>
                    </Row>
                </Card>
                <Modal                
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.cartaDeclaratoriaVisible}
                    onOk={this.handleCartaDeclaratoriaClose}
                    onCancel={this.handleCartaDeclaratoriaClose}
                    width={'60%'}
                    style={{'width': '100%', 'height': '60%', 'overflow-y': 'scroll'}}>
                    <div >
                        <h3 style={{'textAlign': 'center'}}>CARTA DECLARATORIA</h3>
                        <h5 style={{'textAlign': 'center'}}>DECLARO BAJO PROTESTA DE DECIR VERDAD</h5>
                        <p style={{'textAlign': 'justify'}}>Con fundamento en el artículo 303 numeral 3, de la Ley General de Instituciones y Procedimientos Electorales (LGIPE), con el objeto de cumplir con los requisitos legales que me permitan aspirar a participar en el Instituto Nacional Electoral como Supervisor Electoral o Capacitador-Asistente Electoral durante el Proceso Electoral 2019-2020.</p>
                        <p style={{'textAlign': 'justify'}}>Asimismo, manifiesto no ser familiar consanguíneo o por afinidad, hasta cuarto grado, de algún vocal de la junta o del Consejo Distrital (consejeros y/o representantes de partidos políticos o candidatos independientes del PEL 2020); también declaro bajo protesta de decir verdad que no milito en ningún partido u organización política en el último año previo a esta convocatoria ni soy simpatizante de alguno de éstos, no fui representante de partido político o coalición ante casilla en los últimos tres años en procesos electorales federal o estatal, ni he participado activamente en alguna campaña electoral; y que los datos asentados corresponden a mi domicilio.</p>
                        <ul>
                            <li style={{'textAlign': 'justify'}}>Que la documentación original que se exhibe ante el Instituto Nacional Electoral para acreditar mi perfil académico, mi experiencia laboral y mi calidad ciudadana son auténticos.</li>
                            <li style={{'textAlign': 'justify'}}>Estar de acuerdo en someterme a las evaluaciones que determinen las autoridades del Instituto Nacional Electoral.</li>
                            <li style={{'textAlign': 'justify'}}>Tener disposición para dedicarme a las actividades para las que se me contrate, incluso de tiempo completo para cubrirlas a cabalidad en las condiciones en que se requiera; por lo que me obligo a cumplir completamente y de manera prioritaria los servicios y actividades objeto de la contratación, dedicando el tiempo necesario para llevarlos a cabo, siendo incompatible cualquier otro empleo dentro del periodo y tiempo destinado para la realización de los mismos.</li>
                            <li style={{'textAlign': 'justify'}}>En su caso, apoyar en las actividades que se realicen durante el cómputo distrital y demás relacionadas en el contrato de prestación de servicios respectivo.</li>
                            <li style={{'textAlign': 'justify'}}>Devolver el material o instrumentos de trabajo proporcionados por el Instituto Nacional Electoral una vez concluido el periodo de contratación.</li>
                        </ul>              
                        <p style={{'textAlign': 'justify'}}>Por lo que autorizo al Instituto Nacional Electoral para que realice las investigaciones que considere pertinentes, en relación con lo manifestado y declarado en los puntos anteriores que y caso de incurrir en falsedad, se dé por terminada mi relación contractual sin responsabilidad para el Instituto Nacional Electoral.</p>
                        <p style={{'textAlign': 'justify'}}>Finalmente, manifiesto que, en caso de contratación, me conduciré durante las actividades de supervisión o de capacitación y asistencia electoral conforme a los principios de certeza, legalidad, independencia, imparcialidad, máxima publicidad y objetividad.</p>
                    </div>                    
                </Modal>
           </React.Fragment>                                            
        );          
    }
};
export default DatosOtros5;
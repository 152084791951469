import React, {Component} from 'react'
import "antd/dist/antd.css";
import Template from '../interfaz/Template';
import {connect} from 'react-redux';
import AuthenticatedComponent from '../AuthenticatedComponent'
import emitirMensaje from '../../redux/actions/emitirMensaje'

export class AcudirJDE extends Component{   
    componentDidMount() {
        this.props.emitirMensaje(
           {
               id: 'mensajeUsuario',
               tipoMensaje: 'error',
               contenido: 'Encontramos observaciones en tus documentos, debes acudir a la Junta Distrital más cercana.',
           }
       ) 
    }
    
    funcionListo= () => {

    }

    render(){
        return(
            <AuthenticatedComponent listo={this.funcionListo}>
                <Template contenido={
                    <React.Fragment>
                        <center>
                            <p></p>
                            <p></p>
                            <h1> <font size="24">:(</font></h1>
                            <font size="5">Encontramos observaciones en tus documentos,<br></br>
                                            debes acudir a la Junta Distrital más cercana.
                            </font>                           
                        </center>
                    </React.Fragment>
                }/> 
            </AuthenticatedComponent>
        )
    } 
}

const mapStateToProps = state => {
    var datosAspirante;
    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    
    return {
        datosAspirante: datosAspirante,       
    }
}

const mapDispatchToProps = {
    emitirMensaje,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcudirJDE);

import React from 'react';
import Template from '../interfaz/Template';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import AuthenticatedComponent from '../AuthenticatedComponent'
import {Layout, Row, Col, Form, Button, message, Modal} from 'antd';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux'
import moment from 'moment'

const {Footer} = Layout;
class ExitoPlatica extends React.Component {
    state = {
        cargado : false,
        tipoMsj : 'info',
        msj : 'Validación de documentos en proceso.',
        correctas : 0,
        lugarExamen : '',
        fechaExamen : '',
        horaExamen: '',
        muestraSedeExamen : false,
        domicilioJunta : '',
        programacionVisible : false,
        programacionPlaticas : []
    }
    constructor(props) {
        super(props);
        this.aspirante = this.props.getAspirante;
        this.validaBanderaPlatica();        
    }

    validaBanderaPlatica = () =>{
            var jSonAspirante = {
                "idDetalleProceso" : this.props.datosAspirante.idDetalleProceso,
                "idAspirante" : this.props.datosAspirante.idAspirante, 
                "idParticipacion" : this.props.datosAspirante.idParticipacion
            }
            
            // Obtenido los datos del servidor
            const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtineAspirante'
                                        , jSonAspirante
                                        , {
                                            headers: {
                                                'Accept': 'application/json'
                                                ,'Content-Type': 'application/json'
                                            }
                                        }
                                    );
            response.then((res)=>{
                console.log('Aspirante' + res.data.entity.aspiranteRes)
                console.log('Correctas: ' + this.props.correctas)
                this.aspirante = res.data.entity.aspiranteRes.aspirante
                this.setState({domicilioJunta : res.data.entity.aspiranteRes.jd})
                if(this.aspirante.bObservacion === 1){
                    this.setState({tipoMsj : 'warning'})
                    this.setState({msj : 'Encontramos observaciones en tus documentos, cargalos de nuevo.'})
                } else if (this.aspirante.bObservacion === 0){
                    this.setState({tipoMsj : 'ok'})
                    this.setState({msj : 'Tus documentos fueron validados.'})
                }
                if(this.props.datosUsuario.tipoUsuario === 'I'){
                    this.setState({tipoMsj : ''})
                    this.setState({msj : ''})
                }
                this.props.emitirMensaje(
                    {
                        id: 'mensajeUsuario',
                        tipoMensaje: this.state.tipoMsj,
                        contenido: this.state.msj,
                    }
                )
                if(this.aspirante.idSedeProgramada)
                    this.obtenerSedeExamen();
            })
            return response.data;
    }

    handleDescargarAcuseClick = () => {        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcusePlatica' 
            ,{
                idProcesoElectoral : this.props.datosAspirante.idProcesoElectoral
                , idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idConvocatoria : this.props.datosAspirante.idConvocatoria
                , folio : this.props.datosAspirante.folio
                , claveElector: this.props.datosAspirante.claveElectorFuar
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")        
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `Capsula_Induccion_${this.props.datosAspirante.claveElectorFuar}.pdf`);
                document.body.appendChild(link);    
                link.click();
                document.body.removeChild(link);
            }      
        }).catch(error => {
            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al descargar el acuse de la cápsula.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al descargar el acuse de la cápsula.', 5);
            }                      
        });
    }

    obtenerSedeExamen = () => {        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/consulta_info_sede' 
            ,{
                idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idSede : this.aspirante.idSedeProgramada
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")        
                }
            },
        ).then(response => {
            if(response.data){
                this.sede = response.data;
                this.setState(
                    {
                        lugarExamen : this.sede.lugar + ' | ' + this.sede.domicilioSede + ', Col. ' + this.sede.colonia
                            + ', C.P. ' + this.sede.cp + ', ' + this.sede.cabeceraDistritalFederal + ', ' + this.sede.nombreEstado + ' ',
                        fechaExamen : this.aspirante.fechaProgramada ? moment(this.aspirante.fechaProgramada).format("DD [de] MMMM [de] YYYY"):null,
                        muestraSedeExamen : true
                    }
                )
            }      
        }).catch(error => {
            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al obtener la sede del examen.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al obtener la sede del examen.', 5);
            }                      
        });
    }

    handleDescargarGuia = () => {        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarGuiaExamen' 
            ,{
                idProcesoElectoral : this.props.datosAspirante.idProcesoElectoral
                , idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idConvocatoria : this.props.datosAspirante.idConvocatoria
                , folio : this.props.datosAspirante.folio
                , claveElector: this.props.datosAspirante.claveElectorFuar
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")        
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `GUIA_DE_ESTUDIO_PEL_2019-2020.pdf`);
                document.body.appendChild(link);    
                link.click();
                document.body.removeChild(link);
            }      
        }).catch(error => {
            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al descargar la guia de estudio.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al descargar la guia de estudio.', 5);
            }                      
        });
    }

    handleProgramacionClick = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/consulta_programacion_platicas' 
            ,{
                idDetalleProceso : this.props.datosAspirante.idDetalleProceso
                , idParticipacion : this.props.datosAspirante.idParticipacion
                , idSede : this.aspirante.idSedeReclutamiento
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")        
                }
            },
        ).then(response => {
            if(response.data){
                this.setState({
                    programacionVisible : true,
                    programacionPlaticas : response.data
                });
            }      
        }).catch(error => {
            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al obtener la programación de pláticas.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al obtener la programación de pláticas.', 5);
            }                      
        });
    }

    handleProgramacionClose = () => {
        this.setState({
            programacionVisible : false
        });
    }

    render() {
        const fechaProgramada = this.state.fechaExamen;
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        const domicilioJunta = this.state.domicilioJunta;
        const programacion = this.state.programacionPlaticas.map(
            (item) => 
                <li>{item.nombreJunta} en {item.domicilioJunta} el día {moment(item.fechaHoraPlatica).format("DD [de] MMMM [de] YYYY")} a las {moment(item.fechaHoraPlatica).format("HH:mm")} hrs.</li>
        ) || {};
        return(
            <AuthenticatedComponent externo={tipoUsuario === 'E'}>
                <Template guia = {tipoUsuario === 'E'} contenido={
                    <div className="exito_container">
                        <div >
                            <span className='exito_titulo'><font size="24">:)</font>Has tomado la cápsula exitosamente.</span>
                            <br/><br/>                        
                                <span className='exito_detalle'>Respuestas correctas: {this.props.correctas} </span>
                            <br/><br/>
                            {this.state.muestraSedeExamen ?
                                <ul id='indicaciones_finalizado'>
                                    <li>Acude en la fecha y hora indicada para presentar tu examen.</li>
                                    <li>
                                        <span><b>Lugar</b>: {this.state.lugarExamen}</span>
                                        <span><b>Fecha</b>: {fechaProgramada}</span>
                                        <span><b> Hora</b>: {this.aspirante.horaProgramada} hrs</span>
                                    </li>
                                    <li>Debes presentarte dos horas antes de la presentación de tu examen</li>
                                
                                </ul>
                                :
                                null}
                                
                            <br/><br/>
                            {tipoUsuario === 'E' ? 
                                <span className='exito_detalle'>En breve recibirás por correo electrónico el comprobante de la Cápsula de Inducción.</span>
                                :   <Footer>
                                        <Row>
                                            <Col id = 'colBtn' span={8}>
                                                <Button type="primary" 
                                                        className="button_style"
                                                        style={{width:170}}
                                                        onClick={() => this.handleDescargarAcuseClick()}>Descargar Acuse
                                                </Button>
                                            </Col>
                                            <Col span={8}></Col>
                                            <Col id = 'colBtn' span={8}>
                                                <Button type="primary" 
                                                        className="button_style"
                                                        style={{width:170}}
                                                        onClick={() => this.handleDescargarGuia()}>Descargar Guia
                                                </Button>
                                            </Col>
                                            <Col span={8}></Col>
                                        </Row>
                                    </Footer>
                            }
                            <br/><br/>
                                <span className='exito_detalle'>{domicilioJunta}</span>
                            <br/><br/>
                                <span className='exito_detalle'>
                                    <a href="#!" onClick={this.handleProgramacionClick}>
                                        Consulta la programación de las pláticas de inducción.
                                    </a>
                                </span>
                            <br/><br/>
                                <span className='exito_detalle'>Gracias por tu participación.</span>
                        </div>
                    </div>
                }/>
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.programacionVisible}
                    onOk={this.handleProgramacionClose}
                    onCancel={this.handleProgramacionClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PROGRAMACIÓN DE PLÁTICAS DE INDUCCIÓN</h3>
                        <ul>
                            {programacion}
                        </ul>
                    </div>           
                </Modal>
            </AuthenticatedComponent>
        );
    }
};

/*const mapStateToProps = state => {
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario,
        ventanaExito: state.sesion.exito
    }
}*/

const mapStateToProps = state => {
    console.log(state)
    var datosAspirante;
    var correctas;
    var datosUsuario;

    if(state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;

    if(state.operaciones)
        correctas = state.operaciones.correctas;
        
    return {
        datosAspirante: datosAspirante,
        correctas : correctas,
        datosUsuario : datosUsuario,
    };
}
const frm = connect(mapStateToProps,  {emitirMensaje})(ExitoPlatica)
export default withRouter(Form.create({ name: 'Cuestionario' })(frm));
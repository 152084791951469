import {inicioSesion, cambioEstatus, terminoSesion, registroTerminado, implantacionAspirante} from '../actions/accionesSesion';

const defaultState = {};

function reducer(state = defaultState, {type, payload}) {
	switch (type) {
		case inicioSesion:
			return payload;
		case cambioEstatus:
			if (!state.datosUsuario)
				state.datosUsuario = {};

			if (!state.datosUsuario.datosAspirante)
				state.datosUsuario.datosAspirante = {};

			state.datosUsuario.datosAspirante.estatusRegistro = payload;

			return state;
		case terminoSesion:
			return payload;
		case registroTerminado:
			state.exito = payload;

			return state;
		case implantacionAspirante:
			state.datosUsuario.datosAspirante = payload;

			return state;
		default: 
			return state;
	}
}

export default reducer;
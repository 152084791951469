import React from 'react';
import {Icon, Button} from 'antd';
import Template from '../interfaz/Template';
import {borrarOperacion} from '../../redux/actions/accionesOperacion'
import {connect} from 'react-redux'
import { Link } from "react-router-dom";

class OperacionExitosa extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.operaciones)
            this.props.history.push('/acceso');
    }

    componentWillUnmount() {
        this.props.borrarOperacion();
    }

    continuar = () => {
        this.props.history.push(this.props.operaciones.irA)
    }

    render() {
        const operaciones = this.props.operaciones || {};

        return(
            <Template contenido={
                <React.Fragment>
                    <div className="exito_container">
                        <div>
                            <span className='exito_titulo'>
                                <span className='cara_feliz'>
                                    :)
                                </span>
                                {operaciones.titulo}
                            </span>
                            <div className='exito_detalle'>
                                {operaciones.detalle}
                            </div>
                            {operaciones.radioDecision === 'permite' ?
                                <Link className='exito_detalle' to='/cargaDocumentos'>
                                    Ir a Carga de documentos
                                </Link> : 
                                null
                            }
                        </div>
                    </div>
                    {operaciones.irA ? (
                        <div className='exito_continuar'>
                            <Button onClick={this.continuar}>Continuar <Icon type="arrow-right" /></Button>
                        </div>
                    ) : null}
                </React.Fragment>
            }
            guia={false}
            contenidoExpandido={true}/>
        );
    }
};

const mapStateToProps = state => {
    return {
        operaciones: state.operaciones,
    }
}

const mapDispatchToProps = {
    borrarOperacion
}

export default connect(mapStateToProps, mapDispatchToProps)(OperacionExitosa);
import React from 'react'
import {Carousel, Layout, Icon} from 'antd'
import TemplateExterno from './interfaz/TemplateExterno'
import homeLateral from '../img/home_lateral.jpg'
import {Link} from "react-router-dom"
import AuthenticatedComponent from './AuthenticatedComponent'

const {Sider, Content} = Layout;

function Home() {
	return (
		<AuthenticatedComponent privado={false}>
			<TemplateExterno bloquearTodo={true}>
				<React.Fragment>
					<Layout className='layout_home'>
						<Content>
							<Carousel autoplay={true} autoplaySpeed={4000} >
								<Pantalla1/>
								<Pantalla2/>
								<Pantalla3/>
								<Pantalla4/>
							</Carousel>
						</Content>
						<Sider width='30%' theme='light'>
							<img id="home_lateral" src={homeLateral}/>
						</Sider>
					</Layout>
					<div id='registrate' className='registrate'>
							<Link to='/registro_aspirante'>¡Regístrate ahora!</Link>
					</div>
				</React.Fragment>
			</TemplateExterno>
		</AuthenticatedComponent>
	);
};

function Pantalla1(_props) {
	return (
		<div id='p1' className='pantalla'>
			<p>
				El INE te invita a colaborar como
				&nbsp;<b>Supervisor</b> o <b>Capacitador Electoral</b>&nbsp;
				para el proceso electoral local 2020 de las
				entidades de Coahuila e Hidalgo.
			</p>
			<p>
				¡Regístrate en línea!
			</p>
		</div>
	);
}

function Pantalla2(_props) {
	return (
		<div id='p2' className='pantalla'>
			<p>¿Qué hace un CAE?</p>
			<ul>
				<li><Icon type="check" /> <span>Visita, sensibiliza, notifica y capacita a los ciudadanos sorteados para ser funcionarios de casilla.</span></li>
				<li><Icon type="check" /> <span>Garantiza la instalación y funcionamiento de las casillas el día de la elección.</span></li>
				<li><Icon type="check" /> <span>Informa sobre el desarrollo de la Jornada Electoral.</span></li>
			</ul>
			<p>¡Llena el formulario y participa!</p>
		</div>
	);
}

function Pantalla3(_props) {
	return (
		<div id='p3' className='pantalla'>
			<p>¿Qué hace un SE?</p>
			<ul>
				<li><Icon type="check" /> <span>Coordina, apoya y verifica las actividades de los Capacitadores-Asistentes Electorales.</span></li>
				<li><Icon type="check" /> <span>Cumple en tiempo y forma con la ubicación, integración y funcionamiento de las mesas directivas de casilla.</span></li>
				<li><Icon type="check" /> <span>Informa sobre el desarrollo de la Jornada Electoral, los mecanismos de recolección y el traslado de los paquetes electorales.</span></li>
			</ul>
		</div>
	);
}

function Pantalla4(_props) {
	return (
		<div id='p4' className='pantalla'>
			<p>¡Tú puedes ser un SE o un CAE!</p>
			<ul>
				<li><Icon type="check" /> <span>Si eres mexicano.</span></li>
				<li><Icon type="check" /> <span>Cuentas con credencial para votar.</span></li>
				<li><Icon type="check" /> <span>Cursaste la secundaria.</span></li>
				<li><Icon type="check" /> <span>No has sido representante de partido político en los últimos 3 años.</span></li>
				<li><Icon type="check" /> <span>No eres militante de un partido político.</span></li>
			</ul>
		</div>
	);
}

export default Home;
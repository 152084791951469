import React, {Component} from 'react'
import {Radio as _Radio} from 'antd'
import {configuraComponente, configuraCompInterno} from '../../utils/funciones';

export {Radio} from 'antd'

const {Group} = _Radio;

class RadioGroup extends Component {
	constructor (props) {
		super(props);
        configuraComponente.bind(this)();
        this.custom.trigger = 'onChange';
    }

    render () {
        const input = (<div>
                            <GroupI disabled={this.props.disabled}
                                name={this.props.name}
                                options={this.props.options}
                                size={this.props.size}
                                _onChange={this.props.onChange}
                                value={this.props.form.getFieldValue(this.props.nombre)}
                                buttonStyle={this.props.buttonStyle}>
                                {this.props.children}
                            </GroupI>
                        </div>);

        return this.funcComunComponent(input, 'radio-container', false);
    }
}

class GroupI extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
    }
    
    render() {
		return (<Group {...this.props}
            value={this.state.valor}
            onChange={this.onChange}>
            {this.props.children}
        </Group>);
	}
}

export default RadioGroup;
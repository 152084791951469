import React, {Component} from 'react'
import Template from '../interfaz/Template';
import {connect} from 'react-redux'
import AuthenticatedComponent from '../../componentes/AuthenticatedComponent'
import axios from 'axios';
import Checkbox from '../interfaz/Checkbox';
import {Form, Row, Col, Button} from 'antd';

export class AspiranteCompulsado extends Component{
    state = {
        cargado: false,
        continuar:false      
   }
      
    funcionListo= () => {
        if (!this.props.datosAspirante)
        this.props.history.goBack();
        
        const idProcesoElectoral = this.props.datosAspirante.idProcesoElectoral;
        const idDetalleProceso =   this.props.datosAspirante.idDetalleProceso;
        const idParticipacion = this.props.datosAspirante.idParticipacion;
        const idConvocatoria = this.props.datosAspirante.idConvocatoria;
        const folio =  this.props.datosAspirante.folio;
        const claveElector =  this.props.datosAspirante.claveElectorFuar;

        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse', {
                    idProcesoElectoral : idProcesoElectoral,
                    idDetalleProceso : idDetalleProceso,
                    idParticipacion : idParticipacion,
                    idConvocatoria : idConvocatoria,
                    folio : folio,
                    claveElector: claveElector

        },{
            responseType: 'blob',
            transformResponse: [ data => {
            let blob = new window.Blob([data], {type: this.props.type});
            this.srcURLObject = window.URL.createObjectURL(blob);
            return this.srcURLObject;
        }]},
        ).then(res => {
            this.setState({cargado:true})
        }
        )       
    } 

    activarBoton=(valor)=>{     
            this.setState({continuar:valor})    
    }

    permitirRegistroJDE = () => {     
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/permitirRegistro', {
            idProcesoElectoral : this.props.datosAspirante.idProcesoElectoral,
            idDetalleProceso : this.props.datosAspirante.idDetalleProceso,
            idParticipacion : this.props.datosAspirante.idParticipacion,
            idAspirante : this.props.datosAspirante.idAspirante,
            claveElector: this.props.datosAspirante.claveElectorFuar,
            jdePermiteRegistro:1,
            usuario: this.props.datosUsuario.username
        },{
            responseType: 'blob',
            transformResponse: [ data => {
            let blob = new window.Blob([data], {type: this.props.type});
            this.srcURLObject = window.URL.createObjectURL(blob);
            return this.srcURLObject;
        }]},
        ).then(res => {
            this.setState({cargado:true})
            this.props.history.push('/cargaDocumentos'); 
        }
        )
    }

    render(){        
        var renglon =""; 
        if(this.props.datosAspirante.origenRegistro === 2)
        {  
            renglon =
            <React.Fragment>
                <div className="mensaje_compulsado">
                                Encontramos que el aspirante registrado es representante o militante de un partido político.
                                <p></p>
                                <hr></hr>
                                Puedes descargar el Acuse de validación del aspirante <a href={this.srcURLObject} download="compulsa.pdf">Aquí</a>
                 
                <Row>
                    <Col>
                        <p></p>
                        <Checkbox nombre="jdePermiteRegistro"
                                form={this.props.form}
                                required={false}
                                style={{ 'textAlign': 'justify' }}
                                onChange={this.activarBoton}
                                >
                            Permitir continuar con la carga de documentos del aspirante.
                        </Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <Button type="primary" 
                                className="button_style"
                                style={{width:220}} 
                                htmlType="submit" 
                                disabled={!this.state.continuar}
                                onClick={this.permitirRegistroJDE}
                                >
                            Continuar 
                        </Button>
                    </Col>
                    <Col span={8}></Col>
                </Row>
                </div>
            </React.Fragment>;
        }else{
            renglon = 
            <div className="mensaje_compulsado">
                            Encontramos que eres representante o militante de un partido político.
                            <p>Acude a tu Junta Distrital Electoral para continuar con el proceso.</p>
                            <hr></hr>
                            Puedes descargar tu Acuse de validación <a href={this.srcURLObject} download="compulsa.pdf">Aquí</a>
            </div>
        }
        
        return(
            <AuthenticatedComponent listo={this.funcionListo}>
            <Template contenido={
                <React.Fragment>
                    <Form>                   
                        {renglon}
                    </Form>  
                </React.Fragment>
            }
            bloqueado={!this.state.cargado}
            />
            </AuthenticatedComponent>
        )
    } 
}

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;
    
    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
        datosUsuario = state.sesion.datosUsuario;
        

    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}

export default Form.create({ name: 'aspiranteCompulsado' })(connect(mapStateToProps)(AspiranteCompulsado));

import {createStore, combineReducers} from 'redux'
import sesion from './reducers/sesion.js';
import mensajesRed from './reducers/mensajesRed'
import operaciones from './reducers/operaciones'

const reducer = combineReducers({
	sesion,
	mensajesRed,
	operaciones,
});

const store = createStore(reducer);

export default store; 
import React from 'react';
import axios from 'axios';
import {Button, message} from 'antd';
import Template from '../interfaz/Template';
import {cargarAspirante} from '../../utils/funciones'
import {registrarTerminado, implantarAspirante} from '../../redux/actions/accionesSesion'
import {connect} from 'react-redux'

class ExitoRegistro extends React.Component {
    
    constructor(props) {
        super(props);

        if (!this.props.ventanaExito) {
            this.props.history.push('/registro_aspirante');
        }            

        this.handleDescargarCompulsaClick  = this.handleDescargarCompulsaClick.bind(this);    
        this.handleAdjuntarDocumentosClick = this.handleAdjuntarDocumentosClick.bind(this);    
    }

    handleDescargarCompulsaClick = () => {        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse' 
            ,{
                idProcesoElectoral : this.props.idProcesoElectoral
                , idDetalleProceso : this.props.idDetalleProceso
                , idParticipacion : this.props.idParticipacion
                , idConvocatoria : this.props.idConvocatoria
                , folio : this.props.folioPrincipal
                , claveElector: this.props.claveElectorFuar
            }
            ,{
                headers: {
                    'Content-Type': 'application/json'
                    , 'Authorization': localStorage.getItem("jwt-token")        
                },
                responseType: 'blob'
            },
        ).then(response => {
            if(response.data){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                link.setAttribute('download', `compulsa_${this.props.claveElectorFuar}.pdf`);
                document.body.appendChild(link);    
                link.click();
                document.body.removeChild(link);
            }      
        }).catch(error => {
            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al descargar la compulsa.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al descargar la compulsa.', 5);
            }                      
        });
    }

    handleAdjuntarDocumentosClick = () => {
        cargarAspirante(this.props.folioAcceso).then(datosAspirante => {
            this.props.implantarAspirante(datosAspirante);
            this.props.history.push('/cargaDocumentos');
        });
    }

    componentWillUnmount() {
        this.props.registrarTerminado(false);
    }

    render() {

        const usuarioInterno = (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I'?true:false); 

        return(
            <Template contenido={
                <div className="exito_container">
                    <div style={{'height': '100%', 'width':'70%', 'text-align':'center'}}>
                        <span className='exito_titulo'><font size="24">:)</font> Has concluido el formulario</span>                        
                        <br/><br/>                        
                        {                            
                            !usuarioInterno &&
                            <>
                                <span className='exito_detalle'>
                                    En breve recibirás un correo electrónico para confirmar tu registro.
                                    Revisa en tu bandeja de entrada o en tus correos no deseados.
                                </span>
                            </>                            
                        }
                        {
                            usuarioInterno &&
                            <>
                                {
                                    
                                    this.props.correoElectronico &&
                                    <>    
                                        <span className='exito_detalle'>
                                            Revisa en tu bandeja de entrada o en tus correos no deseados.
                                        </span>
                                    </>                                    
                                }                        
                                <div className='exito_informacion'>
                                    <div>Otorga al aspirante su usuario y contraseña:</div>
                                    <div className='usu_contr'>
                                        <div><span>Usuario: </span>{this.props.folioAcceso}</div>
                                        <div><span>Contraseña: </span>{this.props.claveElectorFuar}</div>
                                    </div>
                                </div>
                                <br/>        
                                <span className='exito_detalle'>Continúa con:</span>
                                <br/>
                                <div>
                                    <Button type="primary" 
                                            className="button_style"
                                            style={{width:170}}
                                            disabled={this.props.claveElectorFuar.length <= 13}
                                            onClick={() => this.handleDescargarCompulsaClick()}>Descargar Compulsa
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button type="primary" 
                                            className="button_style"
                                            style={{width:170}}
                                            onClick={() => this.handleAdjuntarDocumentosClick()}>Adjuntar Documentos
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }/>
        );
    }
};

const mapStateToProps = state => {
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario
        , ventanaExito: state.sesion.exito
        , folioAcceso: state.operaciones && state.operaciones.folioAcceso ? state.operaciones.folioAcceso : null
        , folioPrincipal: state.operaciones && state.operaciones.folioAcceso ? Number(state.operaciones.folioAcceso.substr(state.operaciones.folioAcceso.length - 4)) : null
        , idProcesoElectoral: state.operaciones && state.operaciones.idProcesoElectoral ? state.operaciones.idProcesoElectoral : null        
        , idDetalleProceso: state.operaciones && state.operaciones.idDetalleProceso ? state.operaciones.idDetalleProceso : null        
        , idParticipacion: state.operaciones && state.operaciones.idParticipacion ? state.operaciones.idParticipacion : null        
        , idConvocatoria: state.operaciones && state.operaciones.idConvocatoria ? state.operaciones.idConvocatoria : null        
        , claveElectorFuar: state.operaciones && state.operaciones.claveElectorFuar ? state.operaciones.claveElectorFuar : null        
        , correoElectronico: state.operaciones && state.operaciones.correoElectronico ? state.operaciones.correoElectronico : null
    }
}

const mapDispatchToProps = {
    registrarTerminado,
    implantarAspirante
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitoRegistro);
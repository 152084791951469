import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Steps} from 'antd';

const { Step } = Steps;

class GuiaEstatus extends Component { 
  render() {
    const datosAspirante = this.props.datosAspirante;
    var estatus;

    if (datosAspirante.estatusRegistro < 4) {
      if (datosAspirante.estatusRegistro === 3)
        estatus = 4;
      else if (datosAspirante.estatusRegistro === 2 &&
               (datosAspirante.bPlatica === 0 || datosAspirante.bPlatica === 1))
        estatus = 3;
      else
        estatus = datosAspirante.estatusRegistro;
    }
    else
      estatus = -1;

    var classPasoAnterior;

    if (datosAspirante.bObservacion)
      classPasoAnterior = 'observaciones';

    const paso1 = estatus === 0 ? "Llena tu solicitud" : "Solicitud registrada";
    const paso2 = estatus <= 1 ? "Carga tus documentos" : "Documentos cargados";
    const paso3 = estatus <= 2 ? "Ve la cápsula de inducción" : "Cápsula concluida";
    const paso4 = estatus <= 3 ? "Por finalizar" : "Has finalizado";

    const  pasos = [paso1,paso2,paso3,paso4]

    return (
            <Steps className={classPasoAnterior}
                   size="" current={estatus}
                   status=""
                   >
              {pasos.map((paso, index) => {
                return <Step key={index} title=' ' description={paso}/>
              })}
            </Steps>      
    )
  }
}

const mapStateToProps = state => {
  var datosAspirante;

  if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante) 
    datosAspirante = state.sesion.datosUsuario.datosAspirante;
  else
    datosAspirante = {estatusRegistro: 0};

  return {
    datosAspirante: datosAspirante,
  };
};

export default withRouter(connect(mapStateToProps)(GuiaEstatus));
  
 
import React from 'react';
import {Row, Col} from 'antd';
import moment from 'moment';
import * as etiquetas from '../../ApplicationResources';
import InputText from '../interfaz/InputText';
import Calendario from '../interfaz/Calendario';

class ExperienciaLaboralItem extends React.Component {

    constructor(props) {    
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = {
            fechaInicioEmpresaMaxDate: moment().format(etiquetas.FORMATO_FECHA_MOMENT)  
            , fechaFinEmpresaMinDate: moment('01/01/1900').format(etiquetas.FORMATO_FECHA_MOMENT)   
        };  

        this.handleFechaInicioEmpresaChange = this.handleFechaInicioEmpresaChange.bind(this);
        this.handleFechaFinEmpresaChange = this.handleFechaFinEmpresaChange.bind(this);

    }

    handleFechaInicioEmpresaChange = value => {
        this.setState({
                fechaFinEmpresaMinDate : value ? moment(value).format(etiquetas.FORMATO_FECHA_MOMENT) : moment('01/01/1900').format(etiquetas.FORMATO_FECHA_MOMENT)  
            }, () => {
                this.props.form.validateFields([this.props.fechaFinEmpresa], { force: true });
            }
        );
    }

    handleFechaFinEmpresaChange = value => {
        this.setState({
                fechaInicioEmpresaMaxDate : value ? moment(value).format(etiquetas.FORMATO_FECHA_MOMENT) : moment().format(etiquetas.FORMATO_FECHA_MOMENT)  
            }, () => {
                this.props.form.validateFields([this.props.fechaInicioEmpresa], { force: true });
            }
        );
    }

    render() {

        return(
           <React.Fragment>
                <Row gutter={8}>
                    <Col sm={24} md={12} lg={12}>
                        <InputText nombre={this.props.nombreEmpresa}
                                   placeholder={etiquetas.datos_experiencia_laboral_lbl_nombreEmpresa}     
                                   form={this.props.form}                        
                                   required={false}
                                   msjRequerido={etiquetas.general_dato_requerido}                                       
                                   maxLength={40}
                                   rules={[{
                                        max: 40
                                        , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES                                        
                                    }]}/>
                    </Col>
                    <Col sm={24} md={12} lg={12}>
                        <InputText nombre={this.props.puestoEmpresa}
                                   placeholder={etiquetas.datos_experiencia_laboral_lbl_puestoEmpresa}     
                                   form={this.props.form}                           
                                   required={this.props.form.getFieldValue(this.props.nombreEmpresa)}
                                   msjRequerido={etiquetas.general_dato_requerido}                                       
                                   maxLength={40}
                                   rules={[{
                                        max: 40     
                                        , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES                                   
                                    }]}/>
                    </Col>
                </Row>
                <Row gutter={8}>    
                    <Col sm={24} md={6} lg={6}>
                        <Calendario nombre={this.props.fechaInicioEmpresa}
                                    placeholder={etiquetas.datos_experiencia_laboral_lbl_fechaInicioEmpresa}
                                    label={etiquetas.datos_experiencia_laboral_lbl_fechaInicioEmpresa}
                                    form={this.props.form}
                                    required={false}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    format={etiquetas.FORMATO_FECHA_MOMENT}
                                    formato="dd/mm/aaaa"                                        
                                    showTime={false}
                                    showToday={true}                                    
                                    minDate="01/01/1900"
                                    maxDate={this.state.fechaInicioEmpresaMaxDate}
                                    onChange={(value) => this.handleFechaInicioEmpresaChange(value)}/>                        
                    </Col>
                    <Col sm={24} md={6} lg={6}>
                        <Calendario nombre={this.props.fechaFinEmpresa}
                                    placeholder={etiquetas.datos_experiencia_laboral_lbl_fechaFinEmpresa}
                                    label={etiquetas.datos_experiencia_laboral_lbl_fechaFinEmpresa}
                                    form={this.props.form}
                                    required={false}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    format={etiquetas.FORMATO_FECHA_MOMENT}
                                    formato="dd/mm/aaaa"                                        
                                    showTime={false}
                                    showToday={true}                                    
                                    minDate={this.state.fechaFinEmpresaMinDate}
                                    maxDate={moment().format(etiquetas.FORMATO_FECHA_MOMENT)}
                                    onChange={(value) => this.handleFechaFinEmpresaChange(value)}/>
                    </Col>
                    <Col sm={24} md={12} lg={12}>
                        <InputText nombre={this.props.telefonoEmpresa}
                                    placeholder={etiquetas.datos_experiencia_laboral_lbl_telefonoEmpresa}
                                    realPlaceholder="(999)9999999"
                                    form={this.props.form}
                                    required={false}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={10}
                                    rules={[{
                                            max: 10
                                            , pattern: etiquetas.REGEX_FORMATO_TELEFONO                                   
                                        }]}
                                    custom={{
                                            getValueFromEvent: (event) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return this.props.form.getFieldValue(this.props.telefonoEmpresa);
                                            }
                                        }}/>                        
                    </Col>
                </Row> 
           </React.Fragment>                                            
        );          
    }
};

export default ExperienciaLaboralItem;
export const emisionOperacion = 'EMISION_OPERACION';
export const emitirOperacion = (valor) => {
	return {
		type: emisionOperacion,
		payload: valor,
	};
};

export const borradoOperacion = 'BORRADO_OPERACION';
export const borrarOperacion = () => {
	return {
		type: borradoOperacion,
	};
};
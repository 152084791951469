import React, {Component} from 'react'
import axios from 'axios'
import {Icon} from 'antd'

const body = document.getElementsByTagName('body')[0];
var bodyListener = undefined, bodyDisplacer = undefined;
function func() {
    if (bodyListener === undefined)
        return;

    bodyListener();
} 

body.addEventListener('mouseup', func);
body.addEventListener('mouseleave', func);
body.addEventListener('mousemove', (e) => {
    if (bodyDisplacer === undefined)
        return;

    bodyDisplacer(e.clientX, e.clientY);
});
var SourceViewers = [];
window.onresize = () => {
    if (SourceViewers !== undefined)
        SourceViewers.map(viewer => {
            viewer.resize();
        });
}

class SourceViewer extends Component {
    state = {
        cargado: false,
        pagina: 1,
        classCanvas: 'canvas_invisible',
        tomado: false,
    }

    constructor(props) {
        super(props);
        this.canvasName = this.props.nombre + 'Canvas';
        this.offsetX = 0;
        this.offsetY = 0;
        this.zoom = 1.0;
        SourceViewers[SourceViewers.length] = this;
    }

    componentDidMount() {
        if (this.srcURLObject !== undefined)
            return;

        this.canvas = document.getElementById(this.canvasName);
        this.canvasDiv = document.getElementById(this.canvasName + 'Div');

        const base =  document.getElementById(this.canvasName + 'Base');
        this.props.recursos[this.props.nombre] = base;

        this.rendering = false;

        axios.post(this.props.urlDoc, this.props.data, {
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data], {type: this.props.type});
                this.srcURLObject = window.URL.createObjectURL(blob);

                return this.srcURLObject;
            }],
        }).then(rawData => {
            if (this.props.type === 'application/pdf') {
                window.pdfjsLib.getDocument(rawData.data).then(pdf => {
                    this.pdf = pdf;
                    this.cargarPagina(1);
                });
            }
            else {
                this.img = document.createElement('img');
                this.img.setAttribute('src', this.srcURLObject);

                this.img.onload = () => {
                    this.establecerTamanio();
                    this.canvasDiv.style.height = this.canvas.height + 'px';
                    this.dibujar();
    
                    this.setState({
                        cargado: true,
                        classCanvas: 'canvas_visible'
                    });
                    
                    if (this.props.onLoad)
                        this.props.onLoad();
                };
            }
        });
    }

    clickIn = (e) => {
        this.xAnterior = e.clientX;
        this.yAnterior = e.clientY;
        this.setState({tomado: true});
        bodyListener = this.clickOut;
        bodyDisplacer = this.moverCanvas;
    }

    clickOut = () => {
        bodyListener = undefined;
        bodyDisplacer = undefined;
        this.setState(state => {
            if (state.tomado)
                return {tomado: false}

            return {};
        });
    }

    moverCanvas = (mouseX, mouseY) => {
        const offsetX = this.offsetX + mouseX - this.xAnterior;
        const offsetY = this.offsetY + mouseY - this.yAnterior;
        var cambioX = false, cambioY = false;

        if (offsetX <= 0 &&  this.canvasDiv.clientWidth <= offsetX + this.canvas.width) {
            this.xAnterior = mouseX;
            cambioX = true;
            this.offsetX = offsetX;
        }

        if (offsetY <= 0 && this.canvasDiv.clientHeight <= offsetY + this.canvas.height) {
            this.yAnterior = mouseY;
            cambioY = true;
            this.offsetY = offsetY;
        }

        if (cambioX || cambioY)
            this.dibujar();
    }

    dibujar = () => {
        if (this.rendering)
            return;

        const context = this.canvas.getContext('2d');
        context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        context.setTransform(1, 0, 0, 1, this.offsetX, this.offsetY);

        if (this.pdf !== undefined)
            this.dibujarPDF(context);
        else
            this.dibujarRecurso(context);

        context.setTransform();
    }

    cargarPagina = pagina => {
        this.setState({cargado: false});

        this.pdf.getPage(pagina).then(page => {
            this.page = page;
            this.establecerTamanio();
            this.canvasDiv.style.height = this.canvas.height + 'px';
            this.dibujar();
            
            this.setState({
                cargado: true,
                pagina: pagina,
                classCanvas: 'canvas_visible'
            });

            if (this.props.onLoad)
                this.props.onLoad();
        });
    }

    resize = () => {
        if (this.offsetsValidos())
            this.dibujar();
    }

    offsetsValidos = () => {
        var cambioX = false, cambioY = false;

        if (this.canvasDiv.clientWidth > this.offsetX + this.canvas.width) {
            this.offsetX = this.canvasDiv.clientWidth - this.canvas.width;
            cambioX = true;
        }

        if (this.offsetX > 0) {
            this.offsetX = 0;
            cambioX = true;
        }

        if (this.canvasDiv.clientHeight > this.offsetY + this.canvas.height) {
            this.offsetY = this.canvasDiv.clientHeight - this.canvas.height;
            cambioY = true;
        }

        if (this.offsetY > 0) {
            this.offsetY = 0;
            cambioY = true;
        }

        return cambioX || cambioY;
    }

    hacerZoom = (mul) => {
        const zoomAnt = this.zoom;

        if (mul !== undefined)
            this.zoom = this.zoom * (1 + mul / 10);
        else
            this.zoom = 1.0;

        this.offsetX = (this.offsetX / zoomAnt) * this.zoom;
        this.offsetY = (this.offsetY / zoomAnt) * this.zoom;

        this.establecerTamanio(zoomAnt);
        this.offsetsValidos();
        this.dibujar();
    }

    establecerTamanio = (zoomAnt = 1) => {
        if (this.pdf !== undefined) {
            let viewport = this.page.getViewport(this.zoom);
            this.canvas.width = viewport.width;
            this.canvas.height = viewport.height;
        }
        else {
            let newWidth = this.img.width / zoomAnt * this.zoom;
            let newHeight = this.img.height / zoomAnt * this.zoom;

            this.img.setAttribute('width', newWidth);
            this.img.setAttribute('height', newHeight);

            this.canvas.width = newWidth;
            this.canvas.height = newHeight;
        }
    }

    avanzarRetroceder = (dir) => {
        this.setState(state => {
            if (state.pagina === 1 && dir === -1 ||
                state.pagina === this.pdf._pdfInfo.numPages && dir === 1)
                return state;

            this.cargarPagina(state.pagina + dir);

            return {pagina: state.pagina + dir};
        });
    }

    dibujarPDF(context) {
        this.rendering = true;
        var viewport = this.page.getViewport(this.zoom);
        this.page.render({ canvasContext: context, viewport: viewport }).then(() => {
            this.rendering = false;
        });
    }

    dibujarRecurso(context) {
        context.drawImage(this.img, 0, 0, this.img.width, this.img.height);
    }

    
    componentWillUnmount() {
        if (this.srcURLObject)
            window.URL.revokeObjectURL(this.srcURLObject);
    }

    render() {
        const finalClass = this.state.classCanvas + ' ' + (this.state.tomado ? 'canvas_tomado' : '');
        const idEnlace = this.props.nombre + 'a';

        const rl = this.pdf !== undefined ? (
            <React.Fragment>
                <Icon type="left" onClick={() => this.avanzarRetroceder(-1)}/>
                <Icon type="right" onClick={() => this.avanzarRetroceder(1)}/>
            </React.Fragment>
        ) : null;

        const foco = !this.props.cuadroFoco || this.props.nombre === this.props.cuadroFoco;
        const visorHidden = (!foco || !this.props.visible() ? ' visor_hidden' : '');

        return (
            <React.Fragment>
                <div id={this.canvasName + 'Base'}></div>
                {this.props.titulo ? <div className={'titulo_visor' + visorHidden}>{this.props.titulo}</div> : null}
                <div id={this.canvasName + 'Div'} className={'source_viewer ' + visorHidden}>
                    <span className='redimensionar'>
                        <div>
                            <div>
                                <Icon type="zoom-in" onClick={() => this.hacerZoom(1)}/>
                                {rl}
                                <Icon type="zoom-out" onClick={() => this.hacerZoom(-1)}/>
                                <Icon type="fullscreen" onClick={() => this.hacerZoom()}/>
                                <Icon type="save" onClick={() => {
                                    document.getElementById(idEnlace).click();
                                }}/>
                            </div>
                        </div>
                    </span>
                    <canvas id={this.canvasName}
                            className={finalClass}
                            onMouseDown={this.clickIn}/>
                    <a id={idEnlace} href={this.srcURLObject} download={this.props.nombre}/>
                </div>
            </React.Fragment>
        );
    }
}

export default SourceViewer;
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Input, Button, Table,Popover } from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import ayudaClaveFUAR from '../../../img/ayudaCambioClave.jpg'
const { Column } = Table;

/* Este componente es la tabla que muestra el listado  de busqueda en la  bandeja de entrada*/

function TablaBandejaBusqueda({ datos, banderaVisualizar, keyTabPane, tipoUsuario}) {
  //State que se usa
  const [state, setState] = useState({ searchText: "" });

  /*Metodos para el manejo de tablas */

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ searchText: selectedKeys[0] });
  };

  var handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: "" });
  };

  const functionFilterIcon = filtered => (
    <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
  );
  const functionFilterIconAyuda = filtered => (
    <Icon type="question-circle" style={{ color: filtered ? "#1890ff" : undefined }} />
  );
  const functionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => {
    
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar `}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    );
  };
  const functionFilterDropdownA = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => {
    return (
      <Popover overlayClassName='pop_tooltip'
          disabled={false} 
          content={<img src={ayudaClaveFUAR} width='500px'/>}>
            <marginRight>
            <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
            </marginRight>
        </Popover>
    );
  };

  const functionOnFilterDropdownVisibleChange = visible => {
    /* 	if (visible) {
              setTimeout(() => this.searchInput.select());
            } */
  };

  const functionRender = text => {
    
    return (
      text+""
      /* <Highlighter
        highlightStyle={{ backgroundColor: "#f4a8d1", padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text+""}
      /> */
    );
  };
  const etiquetaObservaciones = (text, record) =>
    record.observacionesArchivos === true ? (
      <span>
        <Icon
          type="reload"
          theme="outlined"
          style={{ fontSize: "16px", color: "#08c" }}
        />
        Con observaciones
      </span>
    ) : (
      <span></span>
    );
  const etiquetasAcciones = (text, record) => (
    <span>
      <Link
        to={`/bandeja/valida/${record.folioAcceso}`}
        className="btn btn-success mr-2"
      >
        <Icon
          type="file"
          theme="outlined"
          style={{ fontSize: "16px", color: "#08c" }}
        />
        Validar documentación
      </Link>
    </span>
  );


  const OC = 1;
  const JL = 2;
  const JD = 3;

  /*   Pintar la tabla     */
  return (
    <Fragment>
       {banderaVisualizar === false ? (
          <Table
            dataSource={datos}
            title={() => "Resultados de búsqueda"}
            scroll={{x:'max-content'}}
          >
            {
            tipoUsuario === OC?
            <Column
            title="Estado" dataIndex="estado"  key={`estado{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["estado"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />:<span></span>
          }
          
          {tipoUsuario === OC || tipoUsuario === JL?
            <Column
            title="Distrito" dataIndex="distrito"  key={`distrito{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["distrito"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
            :<span></span>}
            <Column title="Nombre" dataIndex="nombre" key="nombre"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["nombre"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }} />
            <Column
              title="Clave de elector o FUAR"
              dataIndex="claveElector"
              key="claveElector"
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["claveElector"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column title="Folio" dataIndex="folio" key="folio" 
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["folio"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column
              title="N. de convocatoria"
              dataIndex="numeroConvocatoria"
              key="numeroConvocatoria"
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["numeroConvocatoria"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
            <Column
            align={'right'}
              title={functionFilterDropdownA}
              key="action"
              render={(text, record) => (
                <span>
                  <Link
                    to={`/bandeja/modifica/${record.folio}&${record.claveElector}`}
                    className="btn btn-success mr-2"
                  >
                    <Icon
                      type="edit"
                      theme="outlined"
                      style={{ fontSize: "16px", color: "#08c" }}
                    />
                    Modifica información
                  </Link>
                 {/*  <Link
                    to={`/bandeja/modifica/${record.folioAcceso}`}
                    className="btn btn-success mr-2"
                  >
                    <Icon
                      type="edit"
                      theme="outlined"
                      style={{ fontSize: "16px", color: "#08c" }}
                    />
                    Modifica información
                  </Link> */}
                </span>
              )}
            />
          </Table>
        ) : (
          <span></span>
        )}
    </Fragment>
  );
}

export default withRouter(TablaBandejaBusqueda);

import React from 'react';

function Cubrimiento(props) {
	return (
        <div className='padre_cubrimiento'>
            {props.children}
            {props.cubrir ? 
                (<div className='cubrimiento'>
                    <div></div>
                </div>)
                : null
            }
        </div>
	);
}

export default Cubrimiento;
import React  from 'react';
import {Icon, Row} from 'antd';
import Template from "./../../interfaz/Template.js";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { cargarAspirante } from "../../../utils/funciones";
import { implantarAspirante } from "../../../redux/actions/accionesSesion";
import { emitirOperacion } from "../../../redux/actions/accionesOperacion";
import AuthenticatedComponent from './../../AuthenticatedComponent';
import { connect } from "react-redux";
function Valida({folioAcceso, tipo, history}) {
 
	return (

<Template contenido={
                <div className="exito_container">
                    <div>
                        <span className='exito_titulo'><Icon type="smile" style={{fontSize: '1.5em'}} /> Los datos del aspirante se han actualizado correctamente</span>
                       {/*  <span className='exito_detalle'>En breve recibirás un correo electrónico para confirmar tu registro.</span> */}
                       <br></br>
                       <br></br>
                       <Row>
                       <center>
                       {/*  <Link to={`/bandeja`}className="btn btn-success mr-2" style={{fontSize: '1.5em'}}>
                            Regresar a bandeja
                        </Link> */}
                        {tipo === 0 ?
                        <Link
                            className="btn btn-success mr-2"
                            style={{fontSize: '1.5em'}}
                            onClick={() =>
                            cargarAspirante(folioAcceso).then(datosAspirante => {
                                emitirOperacion({
                                accion:  "captura" 
                                });
                                implantarAspirante(datosAspirante);
                                history.push(`/validacion_documentos`); 
                            })
                            }
                        >
                           Ir a validacion de documentos 
                        </Link>:
                        <Link
                            className="btn btn-success mr-2"
                            style={{fontSize: '1.5em'}}
                            onClick={() =>
                            cargarAspirante(folioAcceso).then(datosAspirante => {
                                
                                implantarAspirante(datosAspirante);
                                history.push(`/cargaDocumentos`); 
                            })
                            }
                        >
                       Ir a carga de documentos 
                    </Link>


                        }
                        
                       </center>
                       </Row>
                      
                       
                    </div>
                </div>
            }
            guia={false}
            />
      /*   <AuthenticatedComponent>
             <div className="contenedor">
                <h1>Validar folio:  {folioAcceso} construcción</h1><br></br>
                <Link to={`/bandeja`}className="btn btn-success mr-2">
                    Regresar bandeja
                </Link>
		    </div>   
        </AuthenticatedComponent> */
		
	)
}

export default withRouter(
    connect(
        _ => {
          return {};
        },
        { implantarAspirante, emitirOperacion }
      )(Valida)
    
    
    
    
    
    
    );
  //  export default withRouter( Valida );

/* export default withRouter(
    connect(
      _ => {
        return {};
      },
      { implantarAspirante, emitirOperacion }
    )(TablaBandeja)
  ); */
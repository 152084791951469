import React, {Component} from 'react'
import { Form, Icon, Row, Col , Button, message, Tooltip, Modal, Popover} from 'antd';
import Template from '../interfaz/Template';
import axios from 'axios';
import AuthenticatedComponent from '../AuthenticatedComponent'
import FileUpload from '../interfaz/FileUpload'
import {connect} from 'react-redux'
import {cambiarEstatus,registrarTerminado,implantarAspirante} from '../../redux/actions/accionesSesion'
import {irAtras} from '../../utils/funciones'
import emitirMensaje from '../../redux/actions/emitirMensaje'
import ayudaClaveElector from '../../img/Carga_tus_documentos_V1.gif'
import ayudaINEFrente from '../../img/Adjunta_credencial_frente.gif'
import ayudaINEReverso from '../../img/Adjunta_credencial_reverso.gif'
import ayudaINEAmbos from '../../img/Adjunta_credencial_ambosLados.gif'

function validacion(archivo){
    const permitido = archivo.type === 'image/jpeg' || archivo.type === 'image/png' || archivo.type === 'application/pdf';
    if (!permitido) {
        message.error('El archivo ' + archivo.name +' es de formato no permitido Solo se permite PDF, JPG y  PNG.',4);
        return false;
    }

    const tamanioPermitido = archivo.size / 1024 / 1024 < 15;
    const archivoVacio = archivo.size === 0;
    if (!tamanioPermitido) {
        message.error('El archivo ' + archivo.name + ' debe ser menor a 15 MB',4);
        return false;
    }
    
    if(archivoVacio){
        message.error('El archivo ' + archivo.name + ' está vacío',4);
    }

    console.log("*****VALOR ARCHIVO EN VALIDACION ****");
    console.log(archivo);
    const archNombrePermitido = validaNombreArchivo(archivo.name);
    if(!archNombrePermitido)
    message.error('El nombre de archivo '+ archivo.name + ' es inválido. El nombre del documento no debe contener caracteres especiales.',4);

    return permitido && tamanioPermitido && archNombrePermitido && !archivoVacio 
}

function validaNombreArchivo(e){
    var patron = /^[0-9a-zA-Z_-\s]+.[pdf/PDF/jpg/JPG/jpeg/JPEG/png/PNG]+$/;
    if(e.match(patron)){
        return true;
    }else{
        return false;
    }
}

export class CargaDocumentos extends Component{
    state = {
         cargado: false,
         cargando: false,
         fileLists:[],
         guardar:false, 
         resCompulsado: false,
         mensajeError: '',
    }

    metadatosArchivos = [];
    irAtras = irAtras.bind(this);

    changeList = (func, datosArchivo) => {
		this.setState(state => {
            const nuevosArchivos = func(state.fileLists[datosArchivo.indice]); 
            if(nuevosArchivos.length>0){
                const repetido = state.fileLists.some(valor=>{return valor.length>0 && valor[0].name === nuevosArchivos[0].name})|| 
                this.listaDocumentos.some(valor => ( valor.archivoValido !== 'N' && nuevosArchivos[0].name === valor.nombreArchivo));
                if(repetido){
                    message.error('El archivo ' + nuevosArchivos[0].name + ' se encuentra previamente especificado')
                    return state.fileLists;
                }
            }
            const copia = state.fileLists.slice();
            copia[datosArchivo.indice] = nuevosArchivos;

            this.iniciaIndices(datosArchivo.indice, nuevosArchivos.length>0);

            datosArchivo.cumplido = copia[datosArchivo.indice].length>0;

            const guardar =!this.metadatosArchivos.some(valor=>{return (valor.obligatorio=== 'S' || valor.archivoValido === 'N') && 
                                                                      !valor.cumplido && !valor.disabled})

			return {fileLists: copia, guardar:guardar};
		});
    }

    componentWillUnmount() {
        this.props.emitirMensaje(
            {
                id: 'mensajeUsuario',
                tipoMensaje: '',
                contenido: '',
            }
        )
    }

    handleAvisoUsuarioNovalidoClick = () => {
        this.setState({
            avisoUsuarioNovalidoVisible : true
        });
    }

    handleAvisoUsuarioNovalidoClose = () => {        
        this.setState({
            avisoUsuarioNovalidoVisible : false
        }, this.props.history.goBack());
    }

    funcionListo= () => {
        if (!this.props.datosAspirante)
        this.props.history.goBack();

        const rolesCaptura = ['ELECINE.ASP.JD', 'ELECINE.ADMIN.OC', 'ELECINE.CAPTURA.OC', 
                              'ELECINE.CAPTURA.JL', 'ELECINE.CAPTURA.JD', 'ELECINE.CAU.OC', 
                              'ELECINE.CAPTURA_VECEYEC.JL','ELECINE.CAPTURA_VECEYEC.JD', 
                              'ELECINE.CAPTURA_VE.JL','ELECINE.CAPTURA_VE.JD',
                              'ELECINE.CAPTURA_CONSEJERO.JL','ELECINE.CONSEJERO.JD',
                              'ELECINE.CAPTURA_VS.JL', 'ELECINE.CAPTURA_VS.JD',
                              'ELECINE.CAPTURA_VERFE.JL', 'ELECINE.CAPTURA_VERFE.JD',
                              'ELECINE.CAPTURA_VOE.JL', 'ELECINE.CAPTURA_VOE.JD'];
                                                           
        if (this.props.datosUsuario){
            if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario)){
                this.handleAvisoUsuarioNovalidoClick(); // No se permite la entrada del usuario
            }
            if(this.props.datosUsuario.rolUsuario === 'ELECINE.CAU.OC')
                this.setState({guardar: false});
        }
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/aspiranteCompulsado', {
            idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
            idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
            idParticipacion: this.props.datosAspirante.idParticipacion,
            idAspirante: this.props.datosAspirante.idAspirante,
            claveElector: this.props.datosAspirante.claveElectorFuar,
            jdePermiteRegistro:0
        },
        ).then(res => {          
           if(res.data.compulsado === 1){
               this.props.history.push('/aspiranteCompulsado'); 
           }else{
               axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/capturaModificaDocumentos', {
                     idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
                     idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
                     idParticipacion: this.props.datosAspirante.idParticipacion,
                     idAspirante: this.props.datosAspirante.idAspirante
                },
                ).then(res => {
                    if(this.props.datosAspirante.estatusRegistro === 2 &&
                       !this.props.datosAspirante.bObservacion){
                        this.props.history.push('/platica_induccion'); 
                        return;
                    }

                    if(this.props.datosAspirante.bObservacion === 1 && 
                      (this.props.datosAspirante.bAcudirJDE === null || this.props.datosAspirante.bAcudirJDE === 0) ||
                      (this.props.datosAspirante.bAcudirJDE === 1 &&  this.props.datosUsuario.tipoUsuario === 'I'))
                      {
                        this.props.emitirMensaje(
                            {
                                id: 'mensajeUsuario',
                                tipoMensaje: 'warning',
                                contenido: 'Encontramos observaciones en tus documentos, cargalos de nuevo.',
                            }
                        )
                    }else if(this.props.datosAspirante.bAcudirJDE === 1 && this.props.datosAspirante.bObservacion === 1 && 
                             this.props.datosUsuario.tipoUsuario === 'E'){
                        this.props.history.push('/acudirJDE'); 
                        return;
                    }
                
                    this.listaDocumentos = res.data.archivosObservaciones.listaObservaciones;
                
                    const relacion = {2:[4], 3:[4], 4:[2,3]}
                    this.listaDocumentos.forEach((valor, indice)=>
                    {
                        
                        this.metadatosArchivos[valor.idTipoDocumento] ={ indice:valor.idTipoDocumento,
                                                                         obligatorio:valor.obligatorio,
                                                                         cumplido:  valor.archivoValido==='S',
                                                                         archivoValido: valor.archivoValido,
                                                                         observaciones:valor.observaciones,
                                                                         capturado: valor.nombreArchivo !== undefined,
                                                                         idDocumento:valor.idDocumento,
                                                                         nombreArchViejo:valor.nombreArchivo
                                                                            };
                    })
                
                    //Cuando es modificación
                    Object.entries(relacion).forEach((valor)=>{
                        const deshabilitar = valor[1].some(_valor=>{
                            const actual = this.metadatosArchivos[_valor];
                            return actual.cumplido
                    })

                    this.metadatosArchivos[valor[0]].disabled = deshabilitar;
                    })
                    
                    const guardar =!this.metadatosArchivos.some(valor=>
                    {
                        return (valor.obligatorio=== 'S' ) &&  !valor.cumplido && !valor.disabled})                                                     
                        this.setState({cargado:true,guardar});
                    }  
                    )
                } //Termina else             
            }
        )      
    }
  
    guardar=()=>{
        this.setState({blocking: true});
        const formData = new FormData();
        var arregloEnviar=[];
        this.metadatosArchivos.forEach(
            (valor)=>{  
                        if(valor.archivo === undefined)
                        return;
                        const metadatoArchivo ={idTipoDocumento:valor.indice,
                                                uid: valor.archivo.uid,
                                                type: valor.archivo.type,
                                                name: valor.archivo.name,
                                                idProcesoElectoral: this.props.datosAspirante.idProcesoElectoral,
                                                idDetalleProceso:   this.props.datosAspirante.idDetalleProceso,
                                                idParticipacion: this.props.datosAspirante.idParticipacion,
                                                idConvocatoria: this.props.datosAspirante.idConvocatoria,
                                                folio: this.props.datosAspirante.folio,
                                                folioAcceso: this.props.datosAspirante.folioAcceso,
                                                idAspirante: this.props.datosAspirante.idAspirante,
                                                claveElectorFuar:this.props.datosAspirante.claveElectorFuar,
                                                origenRegistro:this.props.datosAspirante.origenRegistro,
                                                correo:this.props.datosAspirante.correo,
                                                idDocumento: valor.idDocumento,
                                                usuario:this.props.datosUsuario.username,
                                                nombreArchViejo: valor.nombreArchViejo,
                                                tipoUsuario: this.props.datosUsuario.tipoUsuario                                         
                                               };
                        
                        arregloEnviar.push(metadatoArchivo)
                        formData.append('files', valor.archivo)

                     } 
            )
            
            formData.append('metadatos',new Blob([JSON.stringify(arregloEnviar)],{type:"application/json"}))
            this.setState({cargando: true});
            const jwt = localStorage.getItem('jwt-token');
			axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/guardarDocumentos', formData, {               
				headers: {
                    'Content-Type': 'multipart/form-data',
                     Authorization: jwt
                }
                
			}).then(res => {
                const entidad = res.data.entity;
                this.setState({blocking: false});

                if(entidad.code === 200){
                    this.props.cambiarEstatus(2);
                    this.props.registrarTerminado(true);
                    if(this.props.datosAspirante.bPlatica !== 0)
                        this.props.history.push('/exitoDocumentos');
                    else {
                        const datosAspirante2 = {};
                        //Copiando objeto datosAspirante
                        Object.assign(datosAspirante2, this.props.datosAspirante); 
                        if(this.props.datosUsuario.tipoUsuario === 'E')
                            datosAspirante2.bObservacion = null;
                        else{
                            datosAspirante2.bObservacion = 0;
                            datosAspirante2.estatusRegistro = 3;
                        }
                        this.props.implantarAspirante(datosAspirante2);
                        this.props.history.push('/finalizado');
                    }
                }
                if(entidad.code === 400){		   
                    message.error(entidad.message,5);
                    this.setState({guardar: false});
                    this.setState({cargando: false});
                }
                
			}).catch(error => {
				console.log('Algo malió sal!');
                this.setState({blocking: false});
                this.setState({cargando: false});
                if (error.response &&error.response.data === 'error_token')
                        this.props.history.push('/acceso');
			});
    }

    iniciaIndices(indice, bandera) {
        var indicesCat = [];

        //Cuando se carga un archivo y el indice coincide con una llave del arreglo indicesCat, se deshabilita
        //la carga de archivos para los elementos del arreglo asignado esa llave. 
        //Cuando se quita un archivo, se revisa cada uno de los arreglos de cada llave.
        //Y si el indice coincide con alguno de sus elementos y todos los elementos de ese arreglo no 
        //tienen archivo, se habilita la carga en la llave correspondiente de indicesCat.
        indicesCat[2] = [4];
        indicesCat[3] = [4];
        indicesCat[4] = [2,3];
        
        indicesCat.forEach((valor,index) => {
            if(indice === index && bandera)
                valor.forEach(_valor =>{
                    this.metadatosArchivos[_valor].disabled = true;
                })
            else if(!bandera && valor.some(_valor => (_valor === indice))) {
                var disabled = valor.some(_valor => {
                    if(_valor === indice)
                        return false;

                    return this.metadatosArchivos[_valor].cumplido;
                });

                this.metadatosArchivos[index].disabled = disabled;
            } 
        });
    }

    render(){
        const datosUsuario = this.props.datosUsuario || {};
        var listaRenderizadaDoctosOblig=[];
        var listaRenderizadaDoctosOpcion=[];
     

        if(!this.state.cargado){
            listaRenderizadaDoctosOblig = undefined
            listaRenderizadaDoctosOpcion = undefined
        }else{
            var modObligatorio=false, modOpcional = false;           
            
            this.listaDocumentos.some((valor)=>{
                modObligatorio = modObligatorio || valor.obligatorio === 'S' && (valor.observacion || valor.archivoValido);
                modOpcional = modOpcional || valor.obligatorio === 'N' && (valor.observacion || valor.archivoValido);

                return modObligatorio && modOpcional
            })

            this.listaDocumentos.forEach((valor, indice)=>
            {
                const etiquetaBoton= (valor.archivoValido === 'N'?'Reemplazar':'Adjuntar');
                const columnasObservaciones = (valor.obligatorio === 'S' && modObligatorio) || (modOpcional && valor.obligatorio === 'N')?
                (<React.Fragment>
                    <Col span={6}>{valor.nombreArchivo}</Col>
                    <Col span={3}>{valor.archivoValido === 'S'?
                                <span>
                                    <Icon type="check-circle" style={{ fontSize: '16px', color: '#8BD23F' }} theme="filled" /> Validado</span>:
                                valor.archivoValido === 'N'?
                                <span>
                                    <Icon type="close-circle" style={{ fontSize: '16px', color: 'red' }} theme="filled" /> 
                                    <Tooltip placement="topLeft" title={valor.observaciones}> Con observaciones</Tooltip></span>:null}
                    </Col>
                </React.Fragment>):(null);

                const claveFUARInfo	= 	    <Popover overlayClassName='pop_tooltip' 
                                                     content={<img src={ayudaClaveElector} width='370px'/> }>
                                                <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.45)' }} theme='filled'/>
                                            </Popover>
                var imagenPop = undefined;
                if(valor.idTipoDocumento === 2){
                    imagenPop = ayudaINEFrente;
                }else if(valor.idTipoDocumento === 3){
                    imagenPop = ayudaINEReverso;
                }else if(valor.idTipoDocumento === 4){
                    imagenPop = ayudaINEAmbos;
                }

                var popIne = "";
                if(valor.idTipoDocumento >1 && valor.idTipoDocumento < 5){
                    popIne = <Popover key={'pop'+indice} overlayClassName='pop_tooltip' 
                                            content={<img src={imagenPop} width='370px'/> }
                                            placement = "top">
                                        <span><Icon type="paper-clip" /> {etiquetaBoton}</span>
                              </Popover>
                }else{
                    popIne = <span><Icon type="paper-clip" /> {etiquetaBoton}</span>
                }

                const renglon = <Row key={valor.idTipoDocumento}>
                                    <Col span={6}><font>{valor.nombreDocumento}</font> </Col>
                                    {columnasObservaciones}
                                    <Col span={9}>
                                        {valor.archivoValido !== 'S'?
                                        <FileUpload key={valor.idTipoDocumento}
                                                    datosArchivo={this.metadatosArchivos[valor.idTipoDocumento]}
                                                    disabled = {this.metadatosArchivos[valor.idTipoDocumento].disabled}
                                                    defaultfileList={[]}
                                                    multiple={false}
                                                    changeList={this.changeList}
                                                    validacion={validacion}
                                                    fileList={this.state.fileLists[valor.idTipoDocumento]}
                                                    etiqueta={popIne}
                                                    
                                                    style={{width:220}}
                                                    accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                                    className="button_style"
                                                />
                                        :null}
                                    </Col>
                                </Row>;
                if(valor.obligatorio === 'S'){
                    listaRenderizadaDoctosOblig.push(renglon);
                }else{
                    listaRenderizadaDoctosOpcion.push(renglon);
                }
            })    
        }

        return(
           <AuthenticatedComponent listo={this.funcionListo}>              
                <Template contenido={
                    <div className='carga_documentos'>
                        <div className="titulo_cargaDocumentos">
                            Adjunta tu documentación
                        </div>
                        <div className="info_ayuda">
                            <Icon type="info-circle" theme="filled" />
                            &nbsp;La documentación pueder ser escaneada o fotografiada.
                            <br></br>
                            Tamaño máximo de archivo 15MB y formatos PDF, JPG y PNG
                        </div>
                        <div></div>
                        <div className="subtitulo_cargaDocumentos"><b>Documentos obligatorios</b></div>
                        <hr></hr>
                        <div className="tabla_documentos"> 
                            {listaRenderizadaDoctosOblig}
                            <div className="subtitulo_cargaDocumentos"><b>Documentos opcionales</b></div>
                            <hr></hr>
                            {listaRenderizadaDoctosOpcion}
                            <p></p>
                            <Row>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <Button type="primary" 
                                            className="button_style"
                                            style={{width:220}} 
                                            htmlType="submit" 
                                            disabled={!this.state.guardar}
                                            onClick={this.guardar}
                                    >
                                        Guardar 
                                    </Button>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </div>

                    </div>  
                }
                bloqueado={!this.state.cargado}

                guia={datosUsuario.tipoUsuario==='E'?true:false}
                cargando={this.state.cargando}
                />
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.avisoUsuarioNovalidoVisible}
                    onOk={this.handleAvisoUsuarioNovalidoClose}
                    onCancel={this.handleAvisoUsuarioNovalidoClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
                        <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
                    </div>                    
                </Modal> 
            </AuthenticatedComponent>
        )
    } 
}

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;
    
    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
        datosUsuario = state.sesion.datosUsuario;

    return {
        datosAspirante: datosAspirante,
        datosUsuario: datosUsuario,
    };
}

export default Form.create({ name: 'cargaDocumentos' })(connect(mapStateToProps,{registrarTerminado,cambiarEstatus,implantarAspirante,emitirMensaje})(CargaDocumentos));

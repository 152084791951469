import React, {Component} from 'react'
import {Layout, Card, Form, Button, Modal, Row, Col, Popconfirm} from 'antd'
import moment from 'moment'
import Tiempo from '../interfaz/Tiempo'
import * as etiquetas from '../../ApplicationResources'
import Calendario from '../interfaz/Calendario'
import Select, {Option} from '../interfaz/Select'
import AuthenticatedComponent from '../AuthenticatedComponent'
import "antd/dist/antd.css";
import {withRouter} from 'react-router-dom';
import Template from '../interfaz/Template';
import axios from 'axios';
import {connect} from 'react-redux';
import RadioGroup, {Radio} from '../interfaz/Radio.js';
import {emitirOperacion} from '../../redux/actions/accionesOperacion'
import {toggleMostrar} from '../../utils/funciones'
import Checkbox from '../interfaz/Checkbox'

export class Cuestionario extends Component{
    state = {
        cargado : false,
        cargando: false,
        selectedOption: '',
        preguntas : [],
        respuestas : [],
        tVida : '',
        sedesCargadas: false,
        widthModal: 520,
        idPregunta : 0
    }
    constructor(props) {
        super(props);
      this.getCuestionario();
    }

    getCuestionario = () =>  {
        var jSonTipoVida = {
            "idProcesoElectoral" : this.props.datosAspirante.idProcesoElectoral,
            "idDetalleProceso" : this.props.datosAspirante.idDetalleProceso,
            "idParticipacion" : this.props.datosAspirante.idParticipacion
        }
        
        // Obtenido los datos del servidor
        const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtineTipoVida'
                                    , jSonTipoVida
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        response.then((res)=>{
            this.tipoVida = res.data.entity.resultadoTipoVida
            this.setState({preguntas : this.tipoVida.preguntas})
            this.setState({cargado : true})
            this.setState({tVida : res.data.entity.resultadoTipoVida.tipoVida})
        })
        debugger                        
        return response.data;
    }

    guardaCuestionario = () => {
        console.log(this.state.respuestaSelec)  
        this.props.form.validateFields((_errors, values) => {
            const datosPlatica = {};
            Object.assign(datosPlatica, values);

            this.setState({cargando: true});
            var jSonTipoVida = {
                "idProcesoElectoral" : this.props.datosAspirante.idProcesoElectoral,
                "idDetalleProceso" : this.props.datosAspirante.idDetalleProceso,
                "idParticipacion" : this.props.datosAspirante.idParticipacion,
                "idAspirante" : this.props.datosAspirante.idAspirante,            
                "tipoVida" : this.state.tVida.tipoVida,
                "respuestas" : this.state.respuestas,
                "idSede" : datosPlatica.sede,
                "fechaProgramada" : datosPlatica.fechaExamen,
                "horaProgramada" : (datosPlatica.horaExamen ? datosPlatica.horaExamen.format('HH:mm') : undefined),
                "usuario" : this.props.datosAspirante.folioAcceso
            }

            const jwt = localStorage.getItem('jwt-token');
            // Obtenido los datos del servidor
            const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/guardaCuestionario'
                    , jSonTipoVida
                    , {
                        headers: {
                            'Accept': 'application/json'
                            ,'Content-Type': 'application/json'
                            , Authorization: jwt
                        }
                    }
                );
            response.then((res)=>{
                console.log(res.data.entity.resultadoCuestionario)
                this.props.emitirOperacion({correctas : res.data.entity.resultadoCuestionario.respuestasCorrectas})
                if(this.props.datosAspirante.correo)
                    this.enviaNotificacion();
                else
                    this.props.history.push('/ExitoPlatica')
            }).catch(err => {
                this.setState({cargando: false});

                if (err.response && err.response.data === 'error_token')
                    this.props.history.push('/acceso');
            });
            debugger             
            return response.data;
        });
    }

    enviaNotificacion = () => {
        var jSonNotifica = {
            "asunto" : "Notificación de cápsula de inducción",
            "contenido" : "<center> <p></p> <p></p> <p> <font face=arial size=24>:)</font>&nbsp;&nbsp;&nbsp;" + 
                        "<font face=arial size=6>Has tomado la cápsula de inducción</font> </p> </center> <hr></hr> " + 
                        "<font face=arial size=4>Adjunto encontrarás el acuse correspondiente.</font>",
            "envioArchivos":{
                "idProcesoElectoral":this.props.datosAspirante.idProcesoElectoral,
                "idDetalleProceso":this.props.datosAspirante.idDetalleProceso,
                "participacionGeografica":this.props.datosAspirante.idParticipacion,
                "idConvocatoria":this.props.datosAspirante.idConvocatoria,
                "folio":this.props.datosAspirante.folio,
                "guia" : true,
                "nombresArchivos":["Capsula_Induccion_"+this.props.datosAspirante.claveElectorFuar + ".pdf"]
            },
            "destinatarios" : [
                 this.props.datosAspirante.correo                
            ]
        }

        const response = axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/enviarNotificacion'
                                        , jSonNotifica
                                        , {
                                            headers: {
                                                'Accept': 'application/json'
                                                ,'Content-Type': 'application/json'
                                            }
                                        }
                                    );
            response.then((res)=>{
                this.setState({cargando: false});
                console.log('Notificacion: ' + res.data.entity)
                this.props.history.push('/ExitoPlatica')
            }).catch(() => {
                this.setState({cargando: false});
            })                     

            return response.data;
    }

    cambiaRespuesta = (rSelect) => {
        var idPreg = rSelect.target.value.split('-')
        let listRespuestas = this.state.respuestas.slice();
        listRespuestas[idPreg[1]] =  {
            idPregunta : idPreg[1],
            respuestaSeleccionada  : idPreg[0],
            respuestaCorrecta : idPreg[2]
          }
        this.setState({respuestas : listRespuestas});
        console.log('Respuesta: ' + this.state.respuestas);
    }

    toggleMostrar = toggleMostrar.bind(this);

    obtenerSedes = () => {
        const jwt = localStorage.getItem('jwt-token');
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_sedes', {
            idDetalleProceso: this.props.datosAspirante.idDetalleProceso,
            idParticipacion: this.props.datosAspirante.idParticipacion, 
        },
        {
            responseType: 'application/json',
            headers : {Authorization: jwt}
        }).then(res => {
            this.listaSedes = res.data.entity;
            this.setState({sedesCargadas: true});
        });
    }
    
    render(){
        const listaSedes = this.listaSedes || [];
        const preguntas = this.state.preguntas.map(
            (item) => 
                <li key = {item.id.idPregunta}>
                    {item.pregunta}
                    <RadioGroup nombre = {'pregunta' + item.id.idPregunta} 
                                key={item.id.idPregunta}
                                form = {this.props.form}
                                selectedOption = {this.state.respuestaSelec}
                                onChange = {this.cambiaRespuesta}>
                        <Radio value = {'a-'+item.id.idPregunta + '-' + item.respuestaCorrecta} >
                            {item.respuestaA}
                        </Radio>
                        <Radio value = {'b-'+item.id.idPregunta + '-' + item.respuestaCorrecta} >
                            {item.respuestaB}
                        </Radio>
                        <Radio value = {'c-'+item.id.idPregunta + '-' + item.respuestaCorrecta} >
                            {item.respuestaC}
                        </Radio>
                        <Radio value = {'d-'+item.id.idPregunta + '-' + item.respuestaCorrecta} >
                            {item.respuestaD}
                        </Radio>
                    </RadioGroup>
                </li>
        ) || {};
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        const sinCede = this.props.form.getFieldValue('sinCede');

        return(
            <AuthenticatedComponent externo={tipoUsuario === 'E'}>
                    <Template cargando={this.state.cargando}
                              bloqueado = {!this.state.cargado} contenido={
                        <React.Fragment>
                            <Card title="" bordered={false} className='cuestionario'>
                                <Row gutter={16}>
                                    <Col sm={24} md={24} lg={24}>
                                        <h1>Retroalimentación de la cápsula de inducción</h1>
                                    </Col>
                                </Row>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <form>
                                                <ul>
                                                    {preguntas}
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col span={8}></Col>
                                    <Col span={8}>
                                        {tipoUsuario === 'E'? (
                                                <Button type="primary" 
                                                        className="button_style"
                                                        style={{width:220}} 
                                                        htmlType="submit"
                                                        onClick={this.guardaCuestionario.bind(this, preguntas)}
                                                        >
                                                    Realiza tu evaluación
                                                </Button>
                                            ) : null
                                        }
                                        
                                        {tipoUsuario === 'I'? (
                                                <Button type="primary" 
                                                        className="button_style"
                                                        style={{width:220}} 
                                                        htmlType="submit"
                                                        onClick={this.obtenerSedes}
                                                        >
                                                        Selecciona la sede
                                                </Button>
                                            ) : null
                                        }
                                    </Col>
                                    <Col span={8}></Col>
                                </Row>
                            </Card>
                        </React.Fragment>
                    }/>
                    <Modal visible={this.state.sedesCargadas}
                            title='Has tomado la cápsula de inducción'
                            onCancel={(e) => this.toggleMostrar(e, 'sedesCargadas')}
                            closable={false}
                            okText='Guardar'
                            onOk={this.guardaCuestionario.bind(this, preguntas)}
                            cancelText='Cancelar'
                            zIndex={900}
                            width={700}>
                        
                        <Checkbox nombre='sinCede'
                            form={this.props.form}
                            required={false}
                            rules={[{type: 'boolean'}]}
                            onChange={this.cambioAsignarSede}>
                            <b>Asignar sede en otro momento</b>
                        </Checkbox>
                        
                        <b className={sinCede ? 'inactivo' : ''}>Asigna los datos para presentación del examen:</b>

                        {this.state.sedesCargadas ? (
                            <Select nombre='sede'
                                    placeholder='Sede'
                                    required={!sinCede}
                                    form={this.props.form}
                                    showSearch={true}
                                    disabled={sinCede}>
                                {listaSedes.map(valor => {
                                    return (
                                    <Option kay={valor.id.idSede + 'Sede'} value={valor.id.idSede}>
                                        {'COL. ' + valor.colonia + ' CP. ' + valor.cp + ' DIR. ' + valor.domicilioSede + ', LUGAR: ' + valor.lugar}
                                    </Option>); 
                                })}
                            </Select>)
                            : null}

                        <Row type='flex' justify='space-between'>
                            <Col span={11}>
                                <Calendario nombre='fechaExamen'
                                            label='Fecha'
                                            required={!sinCede}
                                            format={etiquetas.FORMATO_FECHA_MOMENT}
                                            formato="dd/mm/aaaa"
                                            showTime={false}
                                            showToday={true}
                                            form={this.props.form}
                                            minDate={moment().format(etiquetas.FORMATO_FECHA_MOMENT)}
                                            disabled={sinCede}/>
                            </Col>
                            <Col span={11}>
                                <Tiempo nombre='horaExamen'
                                        label='Hora'
                                        format="HH:mm"
                                        formato="HH:mm" 
                                        required={!sinCede}
                                        form={this.props.form}
                                        disabled={sinCede}/>
                                <label id='aviso_JDE'>El aspirante deberá presentarse dos horas antes de la presentación del examen</label>
                            </Col>
                        </Row>
                    </Modal>
            </AuthenticatedComponent>
        )
    } 
}

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if(state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;

    return {
        datosAspirante: datosAspirante,
        datosUsuario : datosUsuario,
    };
}
const frm = connect(mapStateToProps,{emitirOperacion})(Cuestionario)
export default withRouter(Form.create({ name: 'Cuestionario' })(frm));
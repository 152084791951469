import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Input, Button, Table, Modal } from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { cargarAspirante } from "../../../utils/funciones";
import { implantarAspirante } from "../../../redux/actions/accionesSesion";
import { emitirOperacion } from "../../../redux/actions/accionesOperacion";
import { connect } from "react-redux";
import axios from "axios";
const { Column } = Table;

/* Este componente es la tabla que muestra el listado  de cada pestania 
seleccionada en la  bandeja de entrada*/

function TablaBandeja({
  datos,
  banderaVisualizar,
  keyTabPane,
  tituloTabla,
  implantarAspirante,
  history,
  tipoUsuario,
  emitirOperacion,
  rolCaptura
}) {
  //State que se usa
  const [state, setState] = useState({ searchText: "" });
  const [visible, hacerVisible] = useState({
    visible: false,
    confirmDialog: false,
    conCompulsa: 1
  });
  const [aspirante, autorizarAspirante] = useState({
    folioAcceso: "",
    claveElector: "",
    idConvocatoria: ""
  });
  const [exito, setExito] = useState(false);
  const [doc, agregaDoc] = useState({});
  /*Metodos para el manejo de tablas */

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ searchText: selectedKeys[0] });
  };

  var handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: "" });
  };

  const functionFilterIcon = filtered => (
    <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
  );
  const functionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar `}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    );
  };

  const functionOnFilterDropdownVisibleChange = visible => {
    /* 	if (visible) {
              setTimeout(() => this.searchInput.select());
            } */
  };

  const functionRender = text => {
    return (
      text+""
      
      /*<Highlighter
      highlightStyle={{ backgroundColor: "#f4a8d1", padding: 0 }}
      searchWords={[state.searchText]}
      autoEscape
      textToHighlight={text+""}
    />*/
    );
  };




  const asignarSedePantalla = async () => {
    debugger;
    hacerVisible({ visible: false, confirmDialog: true });
    cargarAspirante(aspirante.folioAcceso).then(datosAspirante => {
      implantarAspirante(datosAspirante);
        emitirOperacion({
          accion:  "asignaSede" 
        });
        history.push("/validacion_documentos");
    });
  };


  const consultar = async () => {
    var postData = {
      folioAcceso: aspirante.folioAcceso,
      claveElector: aspirante.claveElector,
      flujoCompulsa: aspirante.conCompulsa, 
      idProceso:aspirante.idProceso,
      idDetalle:aspirante.idDetalle,
      idParticipacion:aspirante.idParticipacion

    };
    const jwt = localStorage.getItem("jwt-token");
    //si tiene compulsa tiene que actualizar BD  mostrar pantallla de exito
    if(aspirante.conCompulsa === 1){  
      const resultado = await axios.post(
        process.env.REACT_APP_API_URL +
          "/centralReclutaSEyCAE/ws/autoriza_aspirante",
        postData,
        { headers: { Authorization: jwt } }
      );
    }
    
    hacerVisible({ visible: false, confirmDialog: true });

    cargarAspirante(aspirante.folioAcceso).then(datosAspirante => {
      implantarAspirante(datosAspirante);
      
      //history.push(`/exito_autoriza/tipoAspirante/${aspirante.conCompulsa}`);
      
      if(aspirante.conCompulsa === 1){
        history.push(`/exito_autoriza/tipoAspirante/${aspirante.conCompulsa}&${aspirante.folioAcceso}`);
        //history.push("/cargaDocumentos");
      }

      // si no se tiene compulsa por deswcargar entonces es aspirante que ya ha subido su documentacion
      if(aspirante.conCompulsa === 0||aspirante.conCompulsa === null){  
        emitirOperacion({
          accion:  "captura" 
        });
        history.push("/validacion_documentos");
        //history.push(`/exito_autoriza/tipoAspirante/${aspirante.conCompulsa}&${aspirante.folioAcceso}`);
        //history.push("/cargaDocumentos");
      }
      //history.push("/cargaDocumentos");
    });
  };
  const descargarCompulsa = async (record) => {
    
    const idProcesoElectoral = record.idProceso;
        const idDetalleProceso =   record.idDetalle;
        const idParticipacion = record.idParticipacion;
        const idConvocatoria = record.idConvocatoria;
        const folio =  record.folio;
        const claveElector =  record.claveElector;

        
    
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/descargarAcuse', {
                    idProcesoElectoral : idProcesoElectoral,
                    idDetalleProceso : idDetalleProceso,
                    idParticipacion : idParticipacion,
                    idConvocatoria : idConvocatoria,
                    folio : folio,
                    claveElector: claveElector

        },{
            responseType: 'blob',
            transformResponse: [ data => {
            let blob = new window.Blob([data], {type: "application/pdf"});
            var srcURLObject = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', `compulsa_${claveElector}.pdf`);
            document.body.appendChild(link);    
            link.click();
            document.body.removeChild(link); 
           agregaDoc(srcURLObject)
        }]
      },
        ).then(res => {
/* 
          // Crear un Blob desde el PDF Stream 
    const file = new Blob ( 
      [res.data], 
      {type: 'application / pdf'}); 
// Cree una URL a partir del archivo 
    const fileURL = URL.createObjectURL (file); 
// Abra la URL en una nueva ventana 
    window.open (fileURL); 
           // this.setState({cargado:true}) */
        }
        )     
  };
  const mostrarModal = async (record) => {
    
    autorizarAspirante({
      folioAcceso: record.folioAcceso,
      claveElector: record.claveElector,
      conCompulsa:record.conCompulsa,
      idProceso:record.idProceso,
      idDetalle:record.idDetalle,
      idParticipacion:record.idParticipacion
    });
    hacerVisible({ visible: true , conCompulsa: record.conCompulsa});
  };
  const aceptar = async () => {
    keyTabPane === "5" ? asignarSedePantalla(): consultar();
  };
  const descargar = async () => {
    descargarCompulsa();
  };
  const cancelar = async () => {
    autorizarAspirante({});
    hacerVisible({ visible: false , conCompulsa: 1});
  };

  const etiquetaDescargaCompulsa = (text, record) => {
    
    return (
      record.conCompulsa === 1 ?
      <span>
        
        <Button
          onClick={() => descargarCompulsa(record)}
          size="small"
          style={{ width: 90 }}
        >
          Descargar
        </Button>
      </span>
      :
      <span></span>
    );
  };

  const etiquetaAutorizar = (text, record) => {
   
    return (
      record.conCompulsa === 1 ?
      <span>
        <Button onClick={() => mostrarModal(record)} size="small" style={{ width: 180 }} disabled={!rolCaptura} >
          Autorizar
        </Button>

      </span>:
      <span>
      <Button onClick={() => mostrarModal(record)} size="small" style={{ width: 180 }} >
        Revisar documentación 
      </Button>

    </span>

    );
  };
  

  const etiquetaObservaciones = (text, record) =>
    record.observacionesArchivos === true ? (
      <span>
        <Icon type="reload" theme="outlined" style={{ fontSize: "16px", color: "#08c" }} />
        Con observaciones
      </span>
    ) : (
      <span></span>
    );
  const etiquetasAcciones = (text, record) => (
    <span>
      <Link
        className="btn btn-success mr-2"
        onClick={() =>
          cargarAspirante(record.folioAcceso).then(datosAspirante => {
            emitirOperacion({
              accion: keyTabPane === "1" ? "captura" : "consulta"
            });
            implantarAspirante(datosAspirante);

            history.push("/validacion_documentos");
          })
        }
      >
        <Icon
          type="file"
          theme="outlined"
          style={{ fontSize: "16px", color: "#08c" }}
        />
        Validar documentación
      </Link>
    </span>
  );

  const etiquetaAsignarSede = (text, record) => {
   
    return (
      
      record.idSedeProgramada !== null ?
      <span>
      </span>:
      <span>
      <Button onClick={() => mostrarModal(record)} size="small" style={{ width: 180 }} >
         Asignar sede
      </Button>

    </span>

    );
  };
  const OC = 1;
  const JL = 2;
  const JD = 3;

  /*   Pintar la tabla     */
  return (
    <Fragment key={keyTabPane}>
       

      {banderaVisualizar === true ? (
        <Table title={() => tituloTabla} dataSource={datos} scroll={{x:'max-content'}}>
          {tipoUsuario === OC ? (
            <Column
              title="Estado"
              dataIndex="estado"
              key={`estado{keyTabPane}`}
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["estado"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
          ) : (
            <span></span>
          )}

          {tipoUsuario === OC || tipoUsuario === JL ? (
            <Column
              title="Distrito"
              dataIndex="distrito"
              key={`distrito{keyTabPane}`}
              filterDropdown={functionFilterDropdown}
              filterIcon={functionFilterIcon}
              OnFilterDropdownVisibleChange={
                functionOnFilterDropdownVisibleChange
              }
              render={functionRender}
              onFilter={(value, record) => {
                return record["distrito"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }}
            />
          ) : (
            <span></span>
          )}

          <Column
            title="Nombre"
            dataIndex="nombre"
            key={`nombre{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["nombre"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Clave"
            dataIndex="claveElector"
            key={`claveElector{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["claveElector"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Folio"
            dataIndex="folio"
            key={`folio{keyTabPane}`}
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            OnFilterDropdownVisibleChange={
              functionOnFilterDropdownVisibleChange
            }
            render={functionRender}
            onFilter={(value, record) => {
              return record["folio"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          <Column
            title="Sección"
            dataIndex="seccion"
            key="seccion"
            filterDropdown={functionFilterDropdown}
            filterIcon={functionFilterIcon}
            render={functionRender}
            onFilter={(value, record) => {
              return record["seccion"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }}
          />
          {keyTabPane === "1" || keyTabPane === "2" ? (
            <Column title="" key="action" render={etiquetasAcciones} />
          ) : (
            <span></span>
          )}
          {keyTabPane === "1" ? (
            <Column title="" key="obs" render={etiquetaObservaciones} />
          ) : (
            <span></span>
          )}
          {keyTabPane === "3" ? (
            <Column
              title="Compulsa"
              key="compulsa"
              render={etiquetaDescargaCompulsa}
            />
          ) : (
            <span></span>
          )}
          {keyTabPane === "3" ? (
            <Column title="Acción" key="autorizar" render={etiquetaAutorizar} />
          ) : (
            <span></span>
          )}
          {keyTabPane === "4" ? (
            <Column title="Acción" key="revertir" render={etiquetaAutorizar} />
          ) : (
            <span></span>
          )}
           {keyTabPane === "5" ? (
            <Column title="Acción" key="asignarSede" render={etiquetaAsignarSede} />
          ) : (
            <span></span>
          )}
        </Table>
      ) : (
        <span></span>
      )}

      <div>
        <Modal
          title="Confirmar"
          visible={visible.visible}
          confirmLoading={visible.confirmDialog}
          onOk={aceptar}
          onCancel={cancelar}
          okText="Aceptar"
          cancelText="Cancelar"
        >
    {keyTabPane === "5"? <div>Va a asignar sede ¿Desea continuar?</div> :visible.conCompulsa === 1?<div>¿Esta seguro que desea autorizar?</div> :<div>Va a revisar la documentación ¿Desea continuar?</div>}
                    
        </Modal>
      </div>
    </Fragment>
  );
}

export default withRouter(
  connect(
    _ => {
      return {};
    },
    { implantarAspirante, emitirOperacion }
  )(TablaBandeja)
);

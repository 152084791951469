import React, {Component} from 'react'
import {Input} from 'antd'
import {configuraComponente, configuraCompInterno} from '../../utils/funciones';

const _TextArea = Input.TextArea;

class TextArea extends Component {
	constructor (props) {
		super(props);
		configuraComponente.bind(this)();
	}
	
	render () {
		const input = (<TextAreaI className="transicion_input"
							      placeholder={this.props.realPlaceholder}
								  onChange={this.onChange}
								  disabled={this.props.disabled}
								  custom={this.props.custom}
							      onBlur={this.onBlurAndChange}/>);

		return this.funcComunComponent(input, 'textarea-input-container');
	}
}

class TextAreaI extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
	}

	render() {
		return <_TextArea {...this.props}
					value={this.state.valor}
					onChange={this.onChange}/>
	}
}

export default TextArea;
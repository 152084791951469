export const inicioSesion = 'ESTABLECER_SESION';
export const establecerSesion = (valor) => {
	return {
		type: inicioSesion,
		payload: valor,
	};
};

export const cambioEstatus = 'CAMBIAR_ESTATUS';
export const cambiarEstatus = (nuevoEstatus) => {
	return {
		type: cambioEstatus,
		payload: nuevoEstatus
	}
}

export const registroTerminado = 'REGISTRO_TERMINADO';
export const registrarTerminado = (exito) => {
	return {
		type: registroTerminado,
		payload: exito,
	}
}

export const terminoSesion = 'TERMINO_SESION';
export function terminarSesion() {
	return {
		type: terminoSesion,
		payload: {}
	}
}

export const implantacionAspirante = 'IMPLANTACION_ASPIRANTE';
export const implantarAspirante = aspirante => {
	return {
		type: implantacionAspirante,
		payload: aspirante,
	}
}
import React, {Component} from 'react';
import {ConfigProvider, Layout, Button} from 'antd';
import esES from 'antd/es/locale/es_ES';
import {connect} from 'react-redux';
import logoINE from '../../img/logo_INE_blanco-06.png';
import idSistema from '../../img/ID_RECLUTAMIENTO_sistema.png';
import GuiaEstatus from './GuiaEstatus';
import {withRouter} from 'react-router-dom';
import Mensaje from './Mensaje';
import BlockLoader from './Loader.js';

const {Header, Content, Footer} = Layout;

class Template extends Component {
	redireccionar = () => {
		this.props.history.push('/logout');
	}

	render() {
		const bloquear = this.props.bloqueado || this.props.cargando;
		const tipoUsuario = this.props.datosUsuario ? this.props.datosUsuario.tipoUsuario : 'E';

		return (
			<ConfigProvider locale={esES}>   
				<BlockLoader blocking={bloquear && this.props.bloquearTodo}
							 styleClass={'bloq_sobre_todo'}>
					<Layout className="layout template">
						<Header className="header_style">
							<a href="/loginSEyCAE">
								<img id="idSistema" src={idSistema} alt=""/>
							</a>
						</Header>
						<Content className={this.props.contenidoExpandido ? 'contenido_expandido' : undefined}>
							<BlockLoader blocking={bloquear && !this.props.bloquearTodo}>
								<div className="div_logout">
								{this.props.haySesion ? <Button icon="logout" type="" 
										onClick={this.redireccionar}>
										Cerrar sesión
								</Button>
								: ""}
								</div>
								{this.props.guia && tipoUsuario === 'E'? <GuiaEstatus/> : null}
								<div>&nbsp;</div>
								{tipoUsuario === 'E' ? <Mensaje id='mensajeUsuario'/> : null}
								{this.props.bloqueado ? <div className='template_cargando'>Cargando...</div> : 
														this.props.contenido}
							</BlockLoader>
						</Content>
						<Footer className='footer_style'>
							<img id="logoINE" src={logoINE} alt=""/>
							<h5 style={{ color: '#B6B6B6' }}>Versión 1.1 20200213 16:24</h5>
						</Footer>
					</Layout>
				</BlockLoader>
			</ConfigProvider>  
		);
	}
}

Template.defaultProps = {
	guia: true,
	bloqueado: false,
	cargando: false,
	contenidoExpandido: false,
	bloquearTodo: true,
}

const mapStateToProps = (state) => {
	return {
		haySesion: Boolean(state.sesion.datosUsuario && state.sesion.datosUsuario.username),
		datosUsuario: state.sesion.datosUsuario,
	};
};

export default withRouter(connect(mapStateToProps)(Template));
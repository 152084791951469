import React from 'react';
import {Card, Row, Col, Modal, Select as _Select, message, Popover, Icon, Input} from 'antd';
import {connect} from 'react-redux';
import * as etiquetas from '../../ApplicationResources';
import {valueUpperCase} from '../../utils/funciones.js';
import InputText from '../interfaz/InputText';
import Checkbox from '../interfaz/Checkbox';
import Select from '../interfaz/Select';
import ayudaClaveFUAR from '../../img/Credencial_FUAR.gif'

const { Option } = _Select;

class DatosCredencial extends React.Component {

    constructor(props) {    
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = { 
            lstEstados: null
            , lstEstadosDisable: false
            , avisoPrivacidadVisible: false,
            modifica: false 
        };  

    }

    componentDidMount() {
        if(this.props.modifica === true){  // si es modifica entonces desaparece el  guia de estatus
            this.setState({ modifica : true});
        }
    }

    componentWillMount() {
        //if (!this.state.lstEstados) {
            this.props.getEstados()
                        .then(lstEstados => {
                            this.setState(
                                {
                                    lstEstados: lstEstados
                                    , lstEstadosDisable: (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I' && this.props.datosUsuario.idEstado > 0)
                                }
                                , () => {
                                    if (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I' && this.props.datosUsuario.idEstado > 0){
                                        this.props.form.setFieldsValue({
                                            idEstado: this.props.datosUsuario.idEstado
                                        });
                                    }                                    
                            });
                        })
                        .catch(error => {
                            if (error.response && error.response.status === 409){
                                message.warning(error.response.data.message, 5);
                            } else {
                                message.error('Ocurrió un error.', 5);
                            }
                        });
        //}
    }

    handleAvisoPrivacidadClick = (e) => {
        e.preventDefault();
        this.setState({
            avisoPrivacidadVisible : true
        });
    }

    handleAvisoPrivacidadClose = () => {
        this.setState({
            avisoPrivacidadVisible : false
        });
    }

    render() {
        const claveFUAR	= 	<Popover overlayClassName='pop_tooltip'
                                     disabled={this.state.modifica} 
                                content={<img src={ayudaClaveFUAR} width='350px'/>}>
								<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
							</Popover>

        return(
            <React.Fragment>
                <Card title={etiquetas.datos_credencial_lbl_titulo} bordered={false}>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <Checkbox nombre="avisoPrivacidad"
                                      form={this.props.form}
                                      required={true}
                                      msjRequerido={etiquetas.general_dato_requerido}
                                      rules={[{validator : (rule, value, cb) => (value === true ? cb() : cb(true))}]}
                                      style={{ 'textAlign': 'justify' }}
                                      disabled={this.state.modifica}
                                      >
                                He leído el 
                                <a href="#!" onClick={this.handleAvisoPrivacidadClick}> Aviso de Privacidad </a> 
                                y otorgo mi consentimiento para que los datos personales sean tratados conforme al mismo.
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>                        
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre='claveElectorFuar'
                                    placeholder={etiquetas.datos_credencial_lbl_claveElectorFuar}
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    maxLength={18}
                                    disabled={this.props.soloFuar}
                                    customTooltip={claveFUAR}
                                    rules={[{
                                                min: 13
                                                , max: 18
                                                , pattern: etiquetas.REGEX_FORMATO_CLAVE_ELECTOR_FUAR
                                            }]}
                                    custom={
                                            {
                                                getValueFromEvent: valueUpperCase 
                                            }
                                        }/>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col sm={24} md={12} lg={12}>
                            <Select nombre='idEstado'
                                    placeholder={etiquetas.datos_credencial_lbl_estado}                                    
                                    form={this.props.form}
                                    required={true}
                                    msjRequerido={etiquetas.general_dato_requerido}
                                    rules={[{
                                            type: 'number'
                                        }]}
                                    showSearch={true}
                                    onChange={this.props.getConvocatoria}
                                    disabled={this.state.modifica || this.state.lstEstadosDisable} 
                                    >
                                        {
                                            this.state.lstEstados &&
                                            this.state.lstEstados.map((estado, index) => (
                                                <Option key={index} value={estado.idEstado}>{estado.nombreEstado}</Option>
                                            ))
                                        }
                            </Select>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <InputText nombre='seccion'
                                       placeholder={etiquetas.datos_credencial_lbl_seccion}
                                       form={this.props.form}
                                       inputType='text'
                                       required={true}
                                       msjRequerido={etiquetas.general_dato_requerido}
                                       maxLength={4}
                                       rules={[{
                                                max: 4
                                                , pattern: /^\d{1,4}$/i
                                            }]}                                        
                                       onBlur={this.props.getConvocatoria}
                                       custom={{
                                            getValueFromEvent: (event, previo) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return previo;
                                            }
                                        }}
                                        validacionNegocio={!this.props.modifica}
                                        disabled={this.state.modifica}
                                        />
                        </Col>
                    </Row>
                </Card>   
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.avisoPrivacidadVisible}
                    onOk={this.handleAvisoPrivacidadClose}
                    onCancel={this.handleAvisoPrivacidadClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>AVISO DE PRIVACIDAD SIMPLIFICADO</h3>
                        <h5 style={{'textAlign': 'center'}}>PARA EL REGISTRO DE ASPIRANTES A SE Y CAE DEL PROCESO ELECTORAL LOCAL 2019-2020</h5>
                        <p style={{'textAlign': 'justify'}}>El Instituto Nacional Electoral, con domicilio en Viaducto Tlalpan #100, Col. Arenal Tepepan, Alcaldía de Tlalpan, C.P. 14610, Ciudad de México. Los datos personales serán utilizados para el registro de los ciudadanos/as interesados en participar en el proceso de reclutamiento del personal eventual que colaborará con el INE como Supervisores/as o Capacitadores/as-Asistentes Electorales, conforme a la normativa electoral y con lo establecido en los artículos 3, fracción II, 26 y 27 fracción V, de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados</p>
                        <p style={{'textAlign': 'justify'}}>Si desea conocer nuestro aviso de privacidad integral en la siguiente dirección electrónica: 
                            <a href="https://www.ine.mx/transparencia/listado-bases-datos-personales" rel="noopener noreferrer" target="_blank"> https://www.ine.mx/transparencia/listado-bases-datos-personales/</a>.
                        </p>
                    </div>                    
                </Modal>   
           </React.Fragment>                                            
        );          
    }
};

const mapStateToProps = state => {
    
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario,
    };
}

export default connect(mapStateToProps)(DatosCredencial);
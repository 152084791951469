import React, {Component} from 'react'
import {Form} from 'antd';
import {withRouter} from 'react-router-dom';
import Template from './interfaz/Template';
import enConstruccion from '../img/enConstruccion.png'

export class EnConstruccion extends Component{
    
    render(){
        return(
            <Template contenido={
                <React.Fragment>  
                    <center>
                    <img width="260" border="0" src={enConstruccion} alt=""/>
                    <h1>EN CONSTRUCCIÓN</h1>
                    </center>
                </React.Fragment>
            }/> 
        )
    } 
}

export default withRouter(Form.create({ name: 'enConstruccion' })(EnConstruccion));

import React from 'react';
import axios from 'axios';
import {Row, Col, Form, Button, Layout, message, Modal} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import * as etiquetas from '../../ApplicationResources';
import Template from '../interfaz/Template';
import DatosCredencial from './DatosCredencial';
import DatosPersonales from './DatosPersonales';
import DatosDireccion from './DatosDireccion';
import DatosEscolaridad from './DatosEscolaridad';
import MedioConvocatoria from './MedioConvocatoria';
import ExperienciaLaboral from './ExperienciaLaboral';
import DatosOtros1 from './DatosOtros1';
import DatosOtros2 from './DatosOtros2';
import DatosOtros3 from './DatosOtros3';
import DatosOtros4 from './DatosOtros4';
import DatosOtros5 from './DatosOtros5';
import Wizard from '../interfaz/Wizard.js';
import ProgresoRegistro from '../interfaz/ProgesoRegistro';
import {registrarTerminado, cambiarEstatus} from '../../redux/actions/accionesSesion';
import {emitirOperacion} from '../../redux/actions/accionesOperacion'
import AuthenticatedComponent from '../../componentes/AuthenticatedComponent';
import {connect} from 'react-redux';
import siderRegistro from '../../img/sider_registro.png';
import {withRouter} from 'react-router-dom';

moment.locale('es');

const { Item } =Form;

const {Sider, Content} = Layout;

class Aspirante extends React.Component {
    constructor(props) {    
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
        // Setting the initial state 
        this.stateModifica = { 
            folio: null,
            claveElector: null 
        };  
        this.stateVisibleStatus = { 
            statusVisible: true
        }; 

        this.wizard = {};
    }
    state = {
        disableAceptar: true,
        porcentaje: 0,
        cargado: true,
        statusVisible: true, /* bandera de visibilidad de guia*/
        modifica: false,    /* bandera que dice se esta accediendo desde el modifica*/
        cargando: false,
        guia: true,
        rolConsulta: false,
    }

    objetos = [];
    componentDidMount() {
        if(this.props.modifica === true){  // si es modifica entonces desaparece el  guia de estatus
            this.setState({statusVisible:false, modifica : true});
        }
    }
    formularioCorrecto = (valido, _valores, callbackResponse) => {
        if (this.state.disableAceptar !== !valido)
            this.setState({disableAceptar: !valido});            

        this.callbackResponse = callbackResponse;
    }
     
    enviarServidor = () => {
        let aspirante = {};
        
        this.props.objetos.forEach(function (objeto, index) {
            
            const keys = Object.keys(objeto);
            for (const key of keys) {
                const obj = objeto[key];
                if(obj instanceof Object) {
                    if('value' in obj){
                        if(obj.value instanceof moment){
                            if(key === 'tiempoTraslado'){
                                aspirante[key] = moment(obj.value).format('HH:mm');
                            }else{
                                aspirante[key] = obj.value.format('YYYY-MM-DD');
                            }                            
                        } else {
                            aspirante[key] = obj.value !== undefined && obj.value !== ""?obj.value:null;                            
                        }                        
                    }                    
                } else {
                    aspirante[key] = obj;
                }
            }
        });
        const jwt = localStorage.getItem("jwt-token");
        const headers = {
            'Accept': 'application/json'
            ,'Content-Type': 'application/json'
        };

        if(jwt){
            headers['Authorization'] = jwt;
        }

        if(this.state.modifica ===  false){
            this.setState({cargando: true});
            axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/registra_aspirante'
            , aspirante
            , {
                headers
            }
        ).then(_response => {
            this.wizard.current.form.resetFields();
            this.props.registrarTerminado(true);
            this.props.cambiarEstatus(1);
            this.props.emitirOperacion({
                folioAcceso: _response.data
                , idProcesoElectoral: aspirante.idProcesoElectoral
                , idDetalleProceso: aspirante.idDetalleProceso
                , idParticipacion: aspirante.idParticipacion
                , idConvocatoria: aspirante.idConvocatoria
                , claveElectorFuar: aspirante.claveElectorFuar
                , correoElectronico: aspirante.correoElectronico
            });            
            this.props.history.push('/exito_registro');
        }).catch(error => {
            this.setState({cargando: false});

            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                    && error.response.data.causa.includes("Error de validación")){
                    message.warning(error.response.data.message, 5);                      
                } else {
                    message.error('Ocurrió un error al realizar el registro.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al realizar el registro.', 5);
            }                      
        });
        }
        if(this.state.modifica ===  true){
            const jwt = localStorage.getItem("jwt-token");
            this.setState({cargando: true});
            axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/modifica_aspirante'
            , aspirante
            , {
                headers: {
                    'Accept': 'application/json'
                    ,'Content-Type': 'application/json'
                    , Authorization: jwt
                }
            }
        ).then(_response => {
            //this.props.registrarTerminado(true);
           // this.props.cambiarEstatus(1);
            this.props.history.push('/exito_modifica');
        }).catch(error => {
            this.setState({cargando: false});

            if (error.response){
                if (error.response.status === 409){
                    message.warning(error.response.data.message, 5);
                } else if (error.response.data && error.response.data.causa
                                && error.response.data.causa.includes("errores")){                            
                } else {
                    message.error('Ocurrió un error al realizar el registro.', 5);    
                }
            } else {                        
                message.error('Ocurrió un error al realizar el registro.', 5);
            }                      
        });
        }

    }

    callbackPaso = (indice, total) => {
        this.setState({porcentaje: (indice + 1) * (100 / total)});
    }

    getEstados = async () => {

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/estados_convocatorias_vigentes'
                                    , {}
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    getConvocatoria = () => {

       // Limpiando datos dirección
        if(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]){
            Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], {
                'idProcesoElectoral': undefined
                , 'idDetalleProceso': undefined
                , 'idParticipacion': undefined
                , 'idConvocatoria': undefined
            });
        }

        if(this.props.objetos[etiquetas.WIZARD_DATOS_DIRECCION]){
            Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_DIRECCION], {
                'calleNumero': {'value': undefined}
                , 'colonia':  {'value': undefined}
                , 'cp': {'value': undefined} 
                , 'idMunicipio': {'value': undefined}
                , 'idLocalidad': {'value': undefined}                
            });
        }

        // Validando que esten establecidos los valores de Estado y Seccion
        if(!(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value
            && this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value)){ return }

        // Obtenido los datos del servidor
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/convocatoria'
                    , {
                        "idEstado" : this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value, 
                        "seccion" : this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['seccion'].value
                    }
                    , {
                        headers: {
                            'Accept': 'application/json'
                            ,'Content-Type': 'application/json'
                        }
                    }
                ).then(response => {
                    if(response.data !== ""){
                        Object.assign(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL], response.data);
                        this.wizard.current.cambiaNegocio('seccion', true);                        
                    } else { 
                        this.callbackResponse({0: {seccion: {error: "No existe convocatoria activa en la sección."}}});
                        //message.warning('No existe convocatoria activa para el estado y la sección.', 5);
                    }
                }).catch(error => {
                    if (error.response && error.response.status === 409){
                        message.warning(error.response.data.message, 5);
                    } else {
                        message.error('Ocurrió un error.', 5);
                    }
                });
    }

    getMunicipios = async () => {
        // Validando que este establecido el valor de Estado
        if(!this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value){ return }
        
        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/municipios'
                                    , {
                                        "idEstado" : this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value
                                    }
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    getLocalidades = async () => {

        // Validando que esten establecidos los valores de Estado y Municipio
        if(!(this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value 
            && this.props.objetos[etiquetas.WIZARD_DATOS_DIRECCION]['idMunicipio']
            && this.props.objetos[etiquetas.WIZARD_DATOS_DIRECCION]['idMunicipio'].value)){ return }

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/localidades'
                                    , {
                                        "idEstado" : this.props.objetos[etiquetas.WIZARD_DATOS_CREDENCIAL]['idEstado'].value, 
                                        "idMunicipio" : this.props.objetos[etiquetas.WIZARD_DATOS_DIRECCION]['idMunicipio'].value
                                    }
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    getEscolaridades = async () => {

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/escolaridades'
                                    , {}
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    getMediosDifusionAspirante = async () => {

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/medios_difusion_aspirante'
                                    , {}
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    validaAuthenticated = () => {        
        const rolesCaptura = ['ANONYMOUS', 'ELECINE.CAU.OC', 'ELECINE.UNICOM.OC', 'ELECINE.ADMIN.OC', 'ELECINE.CAPTURA.OC', 'ELECINE.CAPTURA.JL', 'ELECINE.CAPTURA.JD','ELECINE.CAPTURA_VECEYEC.JD','ELECINE.CAPTURA_VECEYEC.JL','ELECINE.CAPTURA_VE.JL','ELECINE.CAPTURA_VE.JD','ELECINE.CAPTURA_CONSEJERO.JL','ELECINE.CONSEJERO.JD','ELECINE.CAPTURA_VS.JL','ELECINE.CAPTURA_VERFE.JL','ELECINE.CAPTURA_VOE.JL','ELECINE.CAPTURA_VS.JD','ELECINE.CAPTURA_VERFE.JD','ELECINE.CAPTURA_VOE.JD'];
        if (this.props.datosUsuario && this.props.datosUsuario.tipoUsuario === 'I'){
            this.setState(
                    {
                        guia: false // Se esconde el componente de guia  
                        , rolConsulta: (this.props.datosUsuario.rolUsuario === 'ELECINE.CAU.OC') // Se establece solo consulta
                    },
                    () => {
                        if(!rolesCaptura.includes(this.props.datosUsuario.rolUsuario)){
                            this.handleAvisoUsuarioNovalidoClick(); // No se permite la entrada del usuario
                        }
                    }
                );                      
        }
    } 

    handleAvisoUsuarioNovalidoClick = () => {
        this.setState({
            avisoUsuarioNovalidoVisible : true
        });
    }

    handleAvisoUsuarioNovalidoClose = () => {        
        this.setState({
            avisoUsuarioNovalidoVisible : false
        }, this.props.history.goBack());
    }

    componentWillUnmount() {
        if (this.wizard.current)
            this.wizard.current.cleanWizard();
    }

    render() {
        const botonDeshabilitado = this.state.rolConsulta ? true :  this.state.disableAceptar;
        const boton = (<Button type="primary" 
                            htmlType="submit" 
                            disabled={botonDeshabilitado}
                            onClick={this.enviarServidor}>
                            Guardar cambios
                        </Button>);
        
        return(
            <AuthenticatedComponent listo={this.validaAuthenticated}
                    privado={false}>
                <Template contenido={
                    <div className='registro_container'>
                        <div className='header_registro'>
                            <div className='contenedor_progreso'>
                                <ProgresoRegistro porcentaje={this.state.porcentaje}/>
                            </div>
                        </div>
                        <Layout className='form_registro'>
                            <Sider theme='light' className='side_registro' width='41%'>
                                <img id="siderRegistro" src={siderRegistro} alt=""/>
                            </Sider>
                            <Content className="contenido_anidado">
                                <Form 
                                    layout="horizontal"
                                    labelAlign='left'
                                    colon={true}>
                                    <Wizard referencia={this.wizard}
                                            lista={[                                                   
                                                <DatosCredencial
                                                    callbackResponse={this.callbackResponse} 
                                                    getEstados={this.getEstados}
                                                    getConvocatoria={this.getConvocatoria}
                                                    modifica = {this.props.modifica}
                                                    soloFuar ={this.props.fuar}
                                                    />,
                                                <DatosPersonales
                                                    callbackResponse={this.callbackResponse}
                                                    wizard={this.wizard}/>, 
                                                <DatosDireccion 
                                                    callbackResponse={this.callbackResponse}
                                                    getMunicipios={this.getMunicipios}
                                                    getLocalidades={this.getLocalidades}/>,
                                                <DatosEscolaridad
                                                    callbackResponse={this.callbackResponse}
                                                    getEscolaridades={this.getEscolaridades}/>,
                                                <MedioConvocatoria
                                                    callbackResponse={this.callbackResponse}                                                
                                                    getMediosDifusionAspirante={this.getMediosDifusionAspirante}/>,
                                                <ExperienciaLaboral
                                                    callbackResponse={this.callbackResponse}/>,    
                                                <DatosOtros1
                                                    callbackResponse={this.callbackResponse}/>,
                                                <DatosOtros2
                                                    callbackResponse={this.callbackResponse}/>,      
                                                <DatosOtros3
                                                    callbackResponse={this.callbackResponse}/>,     
                                                <DatosOtros4
                                                    callbackResponse={this.callbackResponse}/>,
                                                <DatosOtros5
                                                    callbackResponse={this.callbackResponse}/>,
                                            ]}
                                            objetos={this.props.objetos} 
                                            callValidacion={this.formularioCorrecto}
                                            callbackPaso={this.callbackPaso}
                                            modifica={this.props.modifica}
                                            botonFinal={boton}
                                    />
                                </Form>
                            </Content>
                        </Layout>
                    </div>
                }
                bloqueado={!this.state.cargado}
                guia={this.state.guia && this.state.statusVisible}
                cargando={this.state.cargando}
                />
                <Modal
                    title={null}
                    footer={null}
                    centered={true}
                    visible={this.state.avisoUsuarioNovalidoVisible}
                    onOk={this.handleAvisoUsuarioNovalidoClose}
                    onCancel={this.handleAvisoUsuarioNovalidoClose}>
                    <div>
                        <h3 style={{'textAlign': 'center'}}>PERMISOS INSUFICIENTES</h3>
                        <h5 style={{'textAlign': 'center'}}>No tiene permiso para realizar esta acción.</h5>
                    </div>                    
                </Modal> 
            </AuthenticatedComponent>     
        );          
    }
};

const mapStateToProps = state => {
    
    const datosUsuario = (state.sesion && state.sesion.datosUsuario) ? state.sesion.datosUsuario: undefined;

    return {
        datosUsuario: datosUsuario,
    };
}

export default withRouter(connect(mapStateToProps, {registrarTerminado, cambiarEstatus, emitirOperacion})(Aspirante));
import React from 'react';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux'
import moment from 'moment'

class Finalizado extends React.Component {
    state = {
        cargado: false,
    }

    constructor(props) {
        super(props);
    }

    listo = () => {
        const datosAspirante = this.props.datosAspirante || {};

        this.espera = (datosAspirante.estatusRegistro === 2 &&
                       !datosAspirante.bObservacion && 
                       datosAspirante.bPlatica === 0);

        if (datosAspirante.estatusRegistro === 3 ||
            this.espera)
            ;
        else
            this.props.history.push('/acceso');

        if (datosAspirante.estatusRegistro !== 3 || datosAspirante.idSedeProgramada)
            this.props.emitirMensaje({
                id: 'mensajeUsuario',
                tipoMensaje: (this.espera ? 'info' :'ok'),
                contenido: (this.espera ? 'Validación de documentos en proceso' : 'Tus documentos fueron validados'),
            });
        
        if(this.props.datosUsuario.tipoUsuario === 'I'){
            this.props.emitirMensaje({
                id: 'mensajeUsuario',
                tipoMensaje: ' ',
                contenido: ' ',
            });
        }            
        
        this.setState({cargado: true});
    }

    render() {
        const datosAspirante = this.props.datosAspirante || {};
        const tipoUsuario = this.props.datosUsuario.tipoUsuario;
        const fechaProgramada = datosAspirante.fechaProgramada ? moment(datosAspirante.fechaProgramada, 'DD/MM/YYYY').format("DD [de] MMMM [de] YYYY") : null;

        return(
            <AuthenticatedComponent listo={this.listo}>
                <Template guia = {tipoUsuario === 'E'} contenido={
                    <div className="exito_container indicaciones_finalizado">
                        <div>
                            <span className='exito_titulo'>
                                <span className='cara_feliz'>
                                    :)
                                </span>
                                Has finalizado
                            </span>
                            <ul id='indicaciones_finalizado'>
                                {datosAspirante.idSedeProgramada ?
                                    <>
                                        <li>Acude en la fecha y hora indicada para presentar tu examen.</li>
                                        <li>
                                            <span><b>Lugar</b>: {datosAspirante.lugarSede}</span>
                                            <span><b>Fecha</b>: {fechaProgramada}</span>
                                            <span><b>Hora</b>: {datosAspirante.horaProgramada} hrs</span>
                                        </li>
                                    </>
                                :
                                    datosAspirante.bObservacion === 0 ?
                                    <li> Espera a que te sean asignados lugar, fecha y hora para la presentación de tu examen.</li>
                                    :
                                    <li> Espera a que tus documentos sean validados por la Junta Distrital Ejecutiva.</li>
                                }
                                <li><b>Importante</b>: si no has acudido de manera presencial a tomar la plática de inducción,
                                    deberás hacerlo presentándote con dos horas de anticipación el día del examen, en la hora y domicilio citado.
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                contenidoExpandido={true}
                bloqueado={!this.state.cargado}/>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante;
    var datosUsuario;

    if(state.sesion.datosUsuario)
        datosUsuario = state.sesion.datosUsuario;
    else
        datosUsuario = {};

    if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    else
        datosAspirante = {estatusRegistro: 0};

    return {
        datosAspirante: datosAspirante,
        datosUsuario : datosUsuario
    }
}

export default connect(mapStateToProps, {emitirMensaje})(Finalizado);
import React from 'react'
import {Icon} from 'antd'
import {connect} from 'react-redux'

function Mensaje(props) {
    if (!props.id || !props.mensajes[props.id])
        return null;

    var tipo, mensaje = props.mensajes[props.id];

    switch(mensaje.tipoMensaje) {
        case 'warning':
            tipo = 'exclamation-circle';
            break;
        case 'info':
            tipo = 'clock-circle';
            break;
        case 'ok':
            tipo = 'check-circle';
            break;
        case 'error':
            tipo = 'exclamation-circle';
            break;
        }

    return (
        <div className={'estilo_mensaje estilo_mensaje_' + mensaje.tipoMensaje}>
            <span className='estilo_mensaje_contenido'>
                {mensaje.contenido}
            </span>
            <span className='estilo_mensaje_icono'><Icon type={tipo}/></span>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        mensajes: state.mensajesRed.mensajes,
    }
}

export default connect(mapStateToProps)(Mensaje);
import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { Card, Row, Col, Form, Button, Icon, Input, Select, message, Typography } from 'antd';
import * as etiquetas from '../../ApplicationResources';
import TemplateExterno from '../interfaz/TemplateExterno';
import {valueUpperCase} from '../../utils/funciones.js';

const { Item } =Form;
const { Option } = Select;
const { Text } = Typography;

class ModificaCorreoComponent extends React.Component {

    constructor(props) {    
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = { 
            lstEstados: null
        };  
    }

    componentWillMount() {
        this.getEstados()
                    .then(lstEstados => {
                        this.setState({lstEstados})
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 409){
                            message.warning(error.response.data.message, 5);
                        } else {
                            message.error('Ocurrió un error.', 5);
                        }
                    });
    }
    
    getEstados = async () => {

        // Obtenido los datos del servidor
        const response = await axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/estados_convocatorias_vigentes'
                                    , {}
                                    , {
                                        headers: {
                                            'Accept': 'application/json'
                                            ,'Content-Type': 'application/json'
                                        }
                                    }
                                );
        //return await response.json();                        
        return await response.data;
    }

    getConvocatoria = () => {

        // Validando que esten establecidos los valores de Estado y Seccion
        if(this.props.form.getFieldValue('idEstado') === undefined 
            || this.props.form.getFieldValue('seccion') === undefined){ return }

        // Obtenido los datos del servidor
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/convocatoria'
                    , {
                        "idEstado" : this.props.form.getFieldValue('idEstado'), 
                        "seccion" : this.props.form.getFieldValue('seccion')
                    }
                    , {
                        headers: {
                            'Accept': 'application/json'
                            ,'Content-Type': 'application/json'
                        }
                    }
                ).then(response => {
                    if(response.data){
                        Object.assign(this.state, response.data);
                    } else { 
                        this.props.form.setFields({
                            seccion: {
                              errors: [new Error('No existe convocatoria activa en la sección.')],
                            },
                        });
                    }
                }).catch(error => {
                    if (error.response && error.response.status === 409){
                        message.warning(error.response.data.message, 5);
                    } else {
                        message.error('Ocurrió un error.', 5);
                    }
                });
    }

    handleCorreoBlur = (value, source) => {

        const correoElectronico = this.props.form.getFieldValue('correoElectronico');
        const confirmaCorreoElectronico = this.props.form.getFieldValue('confirmaCorreoElectronico');

        if(correoElectronico && confirmaCorreoElectronico){
            if(correoElectronico !== confirmaCorreoElectronico){
                if(source === 'correoElectronico'){
                    this.props.form.setFields({
                        correoElectronico: {
                          errors: [new Error('Los correos no son iguales.')],
                        },
                    });
                }
                if(source === 'confirmaCorreoElectronico'){
                    this.props.form.setFields({
                        confirmaCorreoElectronico: {
                          errors: [new Error('Los correos no son iguales.')],
                        },
                    });
                }                
            }
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((errors, values) => {
            if (!errors) {                        
                const datosModificaCorreo = {};
                Object.assign(datosModificaCorreo, values);
                Object.assign(datosModificaCorreo, this.state);
                axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/mofidica_correo'
                , datosModificaCorreo
                , {
                    headers: {
                        'Accept': 'application/json'
                        ,'Content-Type': 'application/json'
                    }
                }).then(response => {
                    this.props.form.resetFields();
                    message.info('En breve recibirás un correo electrónico de confirmación.', 5);                       
                }).catch(error => {
                    if (error.response){
                        if (error.response.status === 409){
                            message.warning(error.response.data.message, 5);
                        } else if (error.response.data && error.response.data.causa
                                        && error.response.data.causa.includes("errores")){                            
                        } else {
                            message.error('Ocurrió un error al realizar la actualización.', 5);    
                        }
                    } else {                        
                        message.error('Ocurrió un error al realizar la actualización.', 5);
                    }      
                });
            }
        });            
    }

    render() {

        const { getFieldDecorator, getFieldsError, isFieldTouched } = this.props.form;

        return(
            <TemplateExterno enlaces={true}>                
                <Form 
                    layout='horizontal'
                    labelAlign='left'
                    colon={true}
                    onSubmit={this.handleSubmit}>
                        
                    <Card title={etiquetas.modifica_correo_lbl_titulo} bordered={false}>
                        <Text style={{color: '#1890ff'}}><Icon type="info-circle" theme="filled" style={{fontSize: '1.0em'}} /> Ingresa tus datos para actualizar el correo que registraste.</Text>
                        <Row>
                            <Col sm={24} md={24} lg={24}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_claveElectorFuar}
                                    required={true}>
                                    {getFieldDecorator('claveElectorFuar', {
                                        getValueFromEvent: valueUpperCase,
                                        rules: [{ required: true
                                                , whitespace: false
                                                , min: 13
                                                , max: 18
                                                , pattern: etiquetas.REGEX_FORMATO_CLAVE_ELECTOR_FUAR
                                                , message: () => this.props.form.getFieldValue('claveElectorFuar') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_claveElectorFuar}                                               
                                            maxLength={18}
                                            allowClear/>,
                                    )}
                                </Item>
                            </Col>    
                        </Row>
                        <Row gutter={8}>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_estado}
                                    required={true}>
                                    {getFieldDecorator('idEstado', {
                                        rules: [{ required: true
                                                , type: 'number'
                                                , message: this.props.form.getFieldValue('idEstado') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Select placeholder={etiquetas.modifica_correo_lbl_estado}
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={this.getConvocatoria}>                                        
                                            {
                                                this.state.lstEstados &&
                                                this.state.lstEstados.map((estado, index) => (
                                                    <Option key={index} value={estado.idEstado}>{estado.nombreEstado}</Option>
                                                ))
                                            }                                        
                                        </Select>,
                                    )}                        
                                </Item>                            
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_seccion}
                                    required={true}>
                                    {getFieldDecorator('seccion', {
                                        getValueFromEvent: (event) => {
                                            if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                return	event.target.value;
                                            }
                                            return this.props.form.getFieldValue('seccion');
                                        },
                                        rules: [{ required: true
                                                , max: 4
                                                , pattern: /^\d{1,4}$/i
                                                , message: () => this.props.form.getFieldValue('seccion') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_seccion}
                                            maxLength={4}
                                            onBlur={this.getConvocatoria}/>,
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>    
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_telefono}
                                    required={false}>
                                    {getFieldDecorator('telefono', {
                                        getValueFromEvent: (event) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return this.props.form.getFieldValue('telefono');
                                        },
                                        rules: [{ required: false
                                                , max: 10
                                                , pattern: etiquetas.REGEX_FORMATO_TELEFONO
                                                , message: () => this.props.form.getFieldValue('telefono') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_telefono}
                                            maxLength={10}/>,
                                    )}                        
                                </Item>
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_cp}
                                    required={true}>
                                    {getFieldDecorator('cp', {
                                        getValueFromEvent: (event) => {
                                                if(etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return this.props.form.getFieldValue('cp');
                                        },
                                        rules: [{ required: true
                                                , len: 5
                                                , pattern: /^\d{5,5}$/i
                                                , message: () => this.props.form.getFieldValue('cp') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_cp}
                                            maxLength={5}/>,
                                    )}                        
                                </Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>    
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_correoElectronico}
                                    required={true}>
                                    {getFieldDecorator('correoElectronico', {
                                        rules: [{ required: true
                                                , whitespace: true
                                                , type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                                , message: () => this.props.form.getFieldValue('correoElectronico') ? etiquetas.general_formato_invalido : etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_correoElectronico}
                                            maxLength={60}
                                            onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                            onBlur={(value) => this.handleCorreoBlur(value, 'correoElectronico')}/>,
                                    )}                        
                                </Item>
                            </Col>
                            <Col sm={24} md={12} lg={12}>
                                <Item
                                    label={etiquetas.modifica_correo_lbl_confirmaCorreoElectronico}
                                    required={true}>
                                    {getFieldDecorator('confirmaCorreoElectronico', {
                                        rules: [{ required: true
                                                , whitespace: true
                                                , type: 'email'
                                                , max: 60
                                                , pattern: etiquetas.REGEX_FORMATO_CORREO
                                                , message: () => this.props.form.getFieldValue('confirmaCorreoElectronico') ? etiquetas.general_formato_invalido:etiquetas.general_dato_requerido }],
                                    })(
                                        <Input className="transicion_input"
                                            placeholder={etiquetas.modifica_correo_lbl_confirmaCorreoElectronico}
                                            maxLength={60}
                                            onPaste={(event) => {
                                                event.preventDefault();
                                            }}
                                            onBlur={(value) => this.handleCorreoBlur(value, 'confirmaCorreoElectronico')}/>,
                                    )}                        
                                </Item>
                            </Col>
                        </Row>
                    </Card>     
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <Item>
                                <Button type="primary" htmlType="submit" >
                                    Aceptar
                                </Button>
                            </Item>
                        </Col>
                    </Row>                    
                </Form>                
            </TemplateExterno>
        );          
    }
};

export default withRouter((Form.create({ name: 'modificaCorreoComponent' })(ModificaCorreoComponent)));
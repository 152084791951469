import React, {Component} from 'react';
import {ConfigProvider, Layout, Icon} from 'antd';
import esES from 'antd/es/locale/es_ES';
import logoINE from '../../img/logo_INE_blanco-06.png';
import Pdf from '../../documentos/convocatoria.pdf';
import imgBandaTitulo from '../../img/id_reclutamiento_externo.png'
import {withRouter} from 'react-router-dom';
import BlockLoader from './Loader.js';
import EnlaceDescarga from './EnlaceDescarga';
import {Link} from "react-router-dom";

const {Header, Content, Footer} = Layout;

class Download extends Component {
	render() {
		return (
			<div className = "App">
			<a href = {Pdf} target = "_blank">Download Pdf</a>
			</div>
		);
	}
}

class TemplateExterno extends Component {
	render() {
		const bloquear = this.props.bloqueado || this.props.cargando;

		return (
			<ConfigProvider locale={esES}>   
				<BlockLoader blocking={bloquear && this.props.bloquearTodo}
							 id='template_externo_bloq'
							 bloquearTodo={true}>
					<Layout className="layout template_externo">
						<Header className="header_style_externo">
							<div id="banda_titulo">
								<a href="/loginSEyCAE">
									<img src={imgBandaTitulo} alt=""/>
								</a>
							</div>
							{this.props.enlaces ?
								<div id='enlaces_login'>
									<Link to='/acceso'><Icon type="user" /> Inicia sesión</Link>
									<hr/>
									<a href = {Pdf} target = "_blank"><Icon type="file-text" theme="filled" />Consulta la convocatoria</a>
									<hr/>
									<Link to='/modifica_correo'><Icon type="question-circle" theme="filled" /> No recibí correo electrónico</Link>
								</div>
								: null
							}
							{/* <EnlaceDescarga urlDoc={process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/carta_declaratoria'}
								nombreDescarga='Prueba 1.pdf'>
								Descargar!
							</EnlaceDescarga> */}
						</Header>
						<Content className={this.props.contenidoExpandido ? 'contenido_expandido' : undefined}>
							<BlockLoader blocking={bloquear && !this.props.bloquearTodo}>
								{this.props.children}
							</BlockLoader>
						</Content>
						<Footer className='footer_style_externo'>
							<img id="logoINE" src={logoINE} alt=""/>
							<h5 style={{ color: '#B6B6B6' }}>Versión 1.1 20200213 16:24</h5>
						</Footer>
					</Layout>
				</BlockLoader>
			</ConfigProvider>  
		);
	}
}

TemplateExterno.defaultProps = {
	bloqueado: false,
	cargando: false,
	enlaces: true,
	contenidoExpandido: false,
}

export default withRouter(TemplateExterno);
import React from 'react';
import {Card, Row, Col} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import RadioGroup, {Radio} from '../interfaz/Radio';
import InputText from '../interfaz/InputText';

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

class DatosOtros4 extends React.Component {

    constructor(props) {
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = { 

        };  

        this.handleVehiculoPropioChange = this.handleVehiculoPropioChange.bind(this);
        this.handleTieneCelularChange = this.handleTieneCelularChange.bind(this);        
        
    }

    handleVehiculoPropioChange = (value) => {          
        this.props.form.setFieldsValue(
                {
                    marcaModelo: null,
                    utilizarVehiculo: null,
                },
                () => {
                    this.props.form.validateFields(['marcaModelo', 'utilizarVehiculo'], { force: true });
                }
            );
    }

    handleTieneCelularChange = (value) => {  
        this.props.form.setFieldsValue(
                {
                    companiaCelular: null,
                    utilizarCelular: null,
                },
                () => {
                    this.props.form.validateFields(['companiaCelular', 'utilizarCelular'], { force: true });
                }
            );
    }
    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_datos_otros_lbl_titulo} bordered={false}>                                                            
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'vehiculoPropio'}
                                        name={'vehiculoPropio'}
                                        label={etiquetas.datos_datos_otros_lbl_vehiculoPropio}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}
                                        onChange={(value) => this.handleVehiculoPropioChange(value)}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="marcaModelo"
                                        placeholder={etiquetas.datos_datos_otros_lbl_marcaModelo}
                                        form={this.props.form}    
                                        disabled={!(this.props.form.getFieldValue("vehiculoPropio") === 'S')}
                                        required={this.props.form.getFieldValue("vehiculoPropio") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={50}
                                        rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES 
                                            }]}/>                            
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'utilizarVehiculo'}
                                        name={'utilizarVehiculo'}
                                        label={etiquetas.datos_datos_otros_lbl_utilizarVehiculo}
                                        form={this.props.form}
                                        disabled={!(this.props.form.getFieldValue("vehiculoPropio") === 'S')}
                                        required={this.props.form.getFieldValue("vehiculoPropio") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'tieneCelular'}
                                        name={'tieneCelular'}
                                        label={etiquetas.datos_datos_otros_lbl_tieneCelular}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}
                                        onChange={(value) => this.handleTieneCelularChange(value)}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="companiaCelular"
                                        placeholder={etiquetas.datos_datos_otros_lbl_companiaCelular}
                                        form={this.props.form}  
                                        disabled={!(this.props.form.getFieldValue("tieneCelular") === 'S')}
                                        required={this.props.form.getFieldValue("tieneCelular") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={50}
                                        rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES 
                                            }]}/>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'utilizarCelular'}
                                        name={'utilizarCelular'}
                                        label={etiquetas.datos_datos_otros_lbl_utilizarCelular}
                                        form={this.props.form}
                                        disabled={!(this.props.form.getFieldValue("tieneCelular") === 'S')}
                                        required={this.props.form.getFieldValue("tieneCelular") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>                    
                </Card>
           </React.Fragment>                                            
        );          
    }
};
export default DatosOtros4;
import React, {Component} from 'react'
import axios from 'axios';
import {terminarSesion} from '../redux/actions/accionesSesion';
import Template from './interfaz/Template'
import {connect} from 'react-redux';

class Logout extends Component {

	componentDidMount() {
		const jwt = localStorage.getItem('jwt-token');

		if (jwt) {
			axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/cierraSesion', {},
			{
				headers: { Authorization: jwt}
			}).finally(() => {
				localStorage.removeItem('jwt-token');
				this.props.history.push('/acceso');
			});
		}
		else {
			this.props.history.push('/acceso');
		}

		this.props.terminarSesion();
	}
	render() {
		return <Template guia={false} bloqueado={true}/>;
	}
}

const mapDispatchToProps = {
	terminarSesion,
};

export default connect(_ => {return {}} , mapDispatchToProps)(Logout);
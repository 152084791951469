import React, {Component} from 'react';
import Template from '../interfaz/Template';
import nuevo from '../../img/registrar_nuevo.png';
import validar from '../../img/validar.png';
import AuthenticatedComponent from '../AuthenticatedComponent'
import { Link } from "react-router-dom";

class MenuJDE extends Component {
    onRegistroAspirante = () => {
        this.props.history.push('/registro_aspirante');
    }

    render() {
        return (
        <AuthenticatedComponent externo={false}>
            <Template contenido={
                <React.Fragment>
                    <div className='titulo_menu_jde'>
                        ¿Qué deseas realizar?
                    </div>
                    <div className='caja_menu_jde'>
                        <span id='nuevo_registro' onClick={this.onRegistroAspirante}>
                            <img src={nuevo}/>
                            <span>Registrar un ciudadano</span>
                        </span>
                        <span id='validar_registro'>
                            <Link to={'/bandeja'} className="btn btn-success mr-2">
                            <img id='validar_registro' src={validar}/>
                            </Link>
                            <span>Validar y dar seguimiento</span>
                        </span>
                    </div>
                </React.Fragment>
            }
            guia={false}/>
        </AuthenticatedComponent>);
    }
}

export default MenuJDE;
import React from 'react';
import Template from '../interfaz/Template';
import AuthenticatedComponent from '../AuthenticatedComponent';
import emitirMensaje from '../../redux/actions/emitirMensaje'
import {connect} from 'react-redux'

class SolicitudDeclinada extends React.Component {
    listo = () => {
        if (this.props.datosAspirante.estatusRegistro !== 4)
            this.props.history.push('/acceso');

        this.props.emitirMensaje({
            id: 'mensajeUsuario',
            tipoMensaje: 'error',
            contenido: 'Tu solicitud ha sido declinada, para mayor información comunícate a la Junta Distrital',
        });    
    }

    render() {
        return(
            <AuthenticatedComponent listo={this.listo}>
                <Template contenido={
                    <div className="mensaje_container indicaciones_finalizado">
                        <div>
                            <span className='mensaje_titulo'>
                                <span className='cara_triste'>
                                    :(
                                </span>
                            </span>
                            <span className='mensaje_detalle'>Tu tramite ha concluido.</span>
                        </div>
                    </div>
                }
                contenidoExpandido={true}/>
            </AuthenticatedComponent>
        );
    }
};

const mapStateToProps = state => {
    var datosAspirante;

    if (state.sesion.datosUsuario && state.sesion.datosUsuario.datosAspirante)
        datosAspirante = state.sesion.datosUsuario.datosAspirante;
    else
        datosAspirante = {estatusRegistro: 0};

    return {
        operaciones: state.operaciones,
        datosAspirante: datosAspirante,
    }
}

export default connect(mapStateToProps, {emitirMensaje})(SolicitudDeclinada);
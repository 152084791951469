import {emisionOperacion, borradoOperacion} from '../actions/accionesOperacion'

const INICIAL_STATE = null;

export function operaciones(state = INICIAL_STATE, {type, payload}){
    switch(type){
        case emisionOperacion:
            const obj = {};
            if (state)
                Object.assign(obj, state);

            Object.assign(obj, payload);

            return obj;
        case borradoOperacion:
            return null;
        default:
            return state;
    }
}

export default operaciones; 
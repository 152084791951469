import React, {Component} from 'react'
import Template from '../interfaz/Template'
import AuthenticatedComponent from '../AuthenticatedComponent'
import {Layout, Form, Button, Modal, Row, Col, Popconfirm} from 'antd'
import Checkbox from '../interfaz/Checkbox'
import TextArea from '../interfaz/TextArea'
import SourceViewer from '../interfaz/SourceViewer'
import RadioGroup, {Radio} from '../interfaz/Radio'
import Select, {Option} from '../interfaz/Select'
import Calendario from '../interfaz/Calendario'
import Tiempo from '../interfaz/Tiempo'
import Cubrimiento from '../interfaz/Cubrimiento'
import InputText from '../interfaz/InputText'
import {connect} from 'react-redux'
import axios from 'axios'
import {irAtras, toggleMostrar, establecerIniciales, compararValores} from '../../utils/funciones'
import {implantarAspirante} from '../../redux/actions/accionesSesion'
import {emitirOperacion, borrarOperacion} from '../../redux/actions/accionesOperacion'
import moment from 'moment'
import * as etiquetas from '../../ApplicationResources'

const {Header, Sider, Footer, Content} = Layout;
var _compararValores;

const rolesCaptura = ['ELECINE.ADMIN.OC', 'ELECINE.CAPTURA.JD', 'ELECINE.CAPTURA.JL', 'ELECINE.CAPTURA.OC', 'ELECINE.CAPTURA_CONSEJERO.JL', 'ELECINE.CAPTURA_VE.JD', 'ELECINE.CAPTURA_VE.JL', 'ELECINE.CAPTURA_VECEYEC.JD', 'ELECINE.CAPTURA_VECEYEC.JL', 'ELECINE.CONSEJERO.JD'];
//const rolesConsulta = ['ELECINE.CAU.OC', 'ELECINE.CONSULTA.JD', 'ELECINE.CONSULTA.JL', 'ELECINE.CONSULTA.OC', 'ELECINE.CONSULTA_EXT.OPL.JD', 'ELECINE.CONSULTA_EXT.OPL.JL', 'ELECINE.CONSULTA_EXT.OPL.JM', 'ELECINE.CONSULTA_EXT.REST.JD', 'ELECINE.CONSULTA_EXT.REST.JL', 'ELECINE.CONSULTA_EXT.REST.OC', 'ELECINE.CONSULTA_RESTRINGIDA.JD', 'ELECINE.CONSULTA_RESTRINGIDA.JL', 'ELECINE.CONSULTA_RESTRINGIDA.OC'];

class ValidacionDocumentos extends Component {
    state = {
        cuadroFoco: undefined,
        tab: 0,
        cargado: false,
        sedesCargadas: false,
        mostrarCarta: false,
        widthModal: 520,
        mostrarCuadroExamen: false,
        validacion: [],
        todosValidos: false,
        cargando: false,
        declina: false,
        activaGuardado: false
    }

    irAtras = irAtras.bind(this);
    
    constructor(props) {
        super(props);

        this.recursos = {};
        this.controlls = [];
        this.establecerIniciales = establecerIniciales.bind(this);
        this.confirmacion = '¿Estás seguro?';
        _compararValores = compararValores.bind(this, this.callbackCambio, this.callbackRegreso);
    }

	UNSAFE_componentWillReceiveProps = (nextProps) => {
        nextProps.form.haValidado = this.props.form.haValidado;
	}

    listo = () => {
        const datosAspirante = this.props.datosAspirante;
        const datosUsuario = this.props.datosUsuario;
        
        if (!datosAspirante) {
            this.props.history.push('/bandeja');
            return;
        }

        const jwt = localStorage.getItem('jwt-token');

        const url = process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_archivo';
        const viewers = [];
        viewers[0] = [];
        viewers[1] = [];

        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_observaciones_archivos', {
            idProcesoElectoral: datosAspirante.idProcesoElectoral,
            idDetalleProceso: datosAspirante.idDetalleProceso,
            idParticipacion: datosAspirante.idParticipacion, 
            idAspirante: datosAspirante.idAspirante,
        },
        {
            responseType: 'application/json',
            headers : {Authorization: jwt}
        }).then(res => {
            const archivosObservaciones = res.data.archivosObservaciones;
            const arregloDocs = archivosObservaciones.listaObservaciones;
            const acudirJDE = archivosObservaciones.acudirJDE ? 'true' : (archivosObservaciones.estatusRegistro === 4 ? 'false' : undefined);
            this.listaObservaciones = [];

            this.props.form.setFields({radioDecision: {value: acudirJDE}});
            this.setState({declina: acudirJDE === 'false'})
            
            arregloDocs.forEach(valor => {
                const renglon = {};
                renglon.idTipoDocumento = valor.idTipoDocumento;
                renglon.titulo = valor.nombreDocumento;
                renglon.src = valor.nombreArchivo;
                renglon.observacion = valor.observaciones;
                renglon.archivoValido = valor.archivoValido;
                const nombre = valor.nombreDocumento.replace(/ (de|para)/g, ' ').toLowerCase();
                renglon.nombre = nombre.split(' ').map((val, index) => {
                    return index > 0 ? val.charAt(0).toUpperCase() + val.substring(1) : val;
                }).join('');

                const nombreArchivoMin = valor.nombreArchivo.toLowerCase();

                if (nombreArchivoMin.endsWith('.jpeg') || nombreArchivoMin.endsWith('.jpg'))
                    renglon.type = 'image/jpeg'
                else if (nombreArchivoMin.endsWith('.png'))
                    renglon.type = 'image/png'
                else
                    renglon.type = 'application/pdf'

                renglon.url = url;

                viewers[valor.obligatorio === 'S' ? 0 : 1].push(renglon);
                this.listaObservaciones[renglon.nombre] = valor;
            });

            function visible(indice) {
                return this.state.tab === indice;
            }

            const cubrir = this.props.operaciones.accion !== 'captura' || datosAspirante.bAcudirJDE === 1 ||
                           datosAspirante.estatusRegistro === 4 ||
                           !rolesCaptura.some(rol => (datosUsuario.rolUsuario === rol));

            this.renderViews = () => {
                const _viewers = {viewers: [], controlls: []};

                for (var i = 0; i < 2; i++) {
                    viewers[i].forEach(data => {
                        _viewers.viewers.push(<SourceViewer key={data.nombre + 'Sv'}
                                                    nombre={data.nombre}
                                                    urlDoc={url}
                                                    titulo={data.titulo}
                                                    type={data.type}
                                                    data={{idProcesoElectoral: datosAspirante.idProcesoElectoral,
                                                        idDetalleProceso: datosAspirante.idDetalleProceso,
                                                        idParticipacion: datosAspirante.idParticipacion, 
                                                        idConvocatoria: datosAspirante.idConvocatoria,
                                                        folio: datosAspirante.folio,
                                                        claveElectorFuar: datosAspirante.claveElectorFuar,
                                                        src: data.src}}
                                                        cuadroFoco={this.state.cuadroFoco}
                                                    recursos={this.recursos}
                                                    visible={visible.bind(this, i)}/>);
                        _viewers.controlls.push(<CuadroValidacion id={data.idTipoDocumento}
                                                        key={data.nombre + 'Ctrl'}
                                                        form={this.props.form}
                                                        nombre={data.nombre}
                                                        titulo={data.titulo}
                                                        cambioFoco={this.cambiarFoco}
                                                        scrollVisor={this.scrollVisor}
                                                        declina={this.state.declina}
                                                        visible={visible.bind(this, i)}
                                                        archivoValido={data.archivoValido}
                                                        observacion={data.observacion}
                                                        cubrir={cubrir}
                                                        cambiarValidacion={this.cambiarValidacion}/>);
                    });
                }

                return _viewers;
            }

            this.setState({cargado: true});
        }).catch(_error => {});
     
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/obtener_sedes', {
            idDetalleProceso: datosAspirante.idDetalleProceso,
            idParticipacion: datosAspirante.idParticipacion, 
        },
        {
            responseType: 'application/json',
            headers : {Authorization: jwt}
        }).then(res => {
            this.listaSedes = res.data.entity;
            this.setState({sedesCargadas: true});
        });
    }

    cambiarFoco = (nuevoCuadro, cb) => {
        this.setState(state => {
            if (nuevoCuadro === state.cuadroFoco)
                return state;

            cb();
            return {cuadroFoco: nuevoCuadro};
        });
    }

    scrollVisor = (idRecurso) => {
        if (this.recursos[idRecurso] === undefined)
            return;

        const offset = this.recursos[idRecurso].offsetTop;
        const divVisor = document.getElementsByClassName('visor_validar')[0];

        divVisor.scrollTop = offset - 15;
    }

    cambiarTab(newTab) {
        this.setState({tab : newTab, cuadroFoco: undefined});
    }

    toggleMostrar = toggleMostrar.bind(this);

    modificaModal = () => {
        const canvas = document.getElementById('cartaDeclararoriaCanvas');
        const totalWidth = canvas.clientWidth + 48;

        this.setState({widthModal: totalWidth >= 520 ? totalWidth : 520});
    }

    manejarRadiosJDE = (rad) => {
        const val = this.props.form.getFieldValue('radioDecision');

        if (this.props.datosAspirante.bAcudirJDE !== 1 &&
            val === rad)
            this.props.form.setFields({radioDecision: {}});

        this.confirmacion = rad === 'permite' ? '¿Estás seguro de que deseas autorizar?' : '¿Estás seguro?';

        this.setState({declina: val !== rad && rad === 'false'});
    }

    cancelarRevision = () => {
        this.props.borrarOperacion();
        this.props.implantarAspirante(null);
        this.irAtras('/bandeja');
    };

    validarRevision = () => {
        var todosValidos = true, validados = true;
        var tab = this.state.tab;
        const fieldsErrors = {};
        this.state.validacion.forEach(valor => {
            if (valor.archivoValido === undefined || valor.archivoValido === null) {
                todosValidos = false;

                const error = new Error('Se debe validar o rechazar cada documento para poder guardar');
                fieldsErrors[valor.nombre + 'Msj'] = {errors: [error, new Error('')]};

                tab = this.listaObservaciones[valor.nombre].obligatorio === 'S' ? 0 : 1;
            }
            else if (valor.archivoValido === 'N')
                validados = false;
        });

        this.props.form.validateFieldsAndScroll((errors) => {
            const keys = errors !== null ? Object.keys(errors) : [];
            this.props.form.haValidado = true;

            const errorGuardar = keys.some(valor => {
                return valor !== 'sede' && valor !== 'fechaExamen' && valor !== 'horaExamen';
            });

            if (todosValidos && !errorGuardar) {
                if (validados)
                    this.setState({mostrarCuadroExamen: true});
                else
                    this.guardarRevision(false);
            }
            else if (!todosValidos)
                this.props.form.setFields(fieldsErrors)

            if (errorGuardar) {
                const campo = keys[0];
                const key = Object.keys(this.listaObservaciones).filter(valor => {return campo.startsWith(valor)})[0];
                const obs = this.listaObservaciones[key];
                tab = obs.obligatorio === 'S' ? 0 : 1;
            }

            const nuevoCuadroFoco = this.state.tab !== tab ? undefined : this.state.cuadroFoco;

            this.setState({
                tab, 
                cuadroFoco: nuevoCuadroFoco,
            }, () => {
                if (errorGuardar)
                    this.props.form.validateFieldsAndScroll();
            });
        });
    }

    guardarRevision = (validados) => {
        this.props.form.validateFieldsAndScroll((errors, values) => {
            const datosAspirante = this.props.datosAspirante;
            const datosUsuario = this.props.datosUsuario;
            const listaObservaciones = [];

            if (this.props.operaciones.accion !== 'asignaSede')
                this.state.validacion.map(valor => {
                    listaObservaciones.push(valor);
                });

            const keys = errors !== null ? Object.keys(errors) : [];
            var error = keys.length !== 0;

            this.setState({cargando: true});

            const guarda = {idProcesoElectoral: datosAspirante.idProcesoElectoral,
                idDetalleProceso: datosAspirante.idDetalleProceso,
                idParticipacion: datosAspirante.idParticipacion, 
                idAspirante: datosAspirante.idAspirante,
                correo: datosAspirante.correo,
                folioAcceso: datosAspirante.folioAcceso,
                acudirJDE: !this.state.todosValidos && values.radioDecision === 'true',
                declinarSolicitud: !this.state.todosValidos && values.radioDecision === 'false',
                permitirRegistro: values.radioDecision === 'permite',
                usuario: datosUsuario.username,
                listaObservaciones,
            };

            if (!error && !values.sinCede && values.sede) {
                guarda.idSedeProgramada = values.sede;
                guarda.fechaProgramada = values.fechaExamen.valueOf();
                guarda.horaProgramada = values.horaExamen.format('HH:mm');
            }
        
            if (!error || !validados) {
                const jwt = localStorage.getItem('jwt-token');
                axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/guardar_observaciones', guarda,
                {
                    responseType: 'application/json',
                    headers : {Authorization: jwt}
                }).then(res => {
                    if (res.data.code === 200) {
                        this.props.borrarOperacion();

                        if (values.radioDecision !== 'permite') {
                            const datosAspiranteMod = Object.assign({}, datosAspirante);
                            datosAspiranteMod.bAcudirJDE = 0;

                            this.props.implantarAspirante(datosAspiranteMod);
                        }

                        this.props.emitirOperacion({
                            titulo: 'Cambios guardados',
                            detalle: 'Los cambios han sido registrados',
                            radioDecision: values.radioDecision,
                            irA: '/bandeja'
                        });
                        this.props.history.push('/operacion_exitosa');
                    }

                    this.setState({cargando: false});
                }).catch(err => {
                    this.setState({cargando: false,
                                   mostrarCuadroExamen: false});

                    if (err.response && err.response.data === 'error_token')
                        this.props.history.push('/acceso');
                });
            }
            else 
                this.setState({cargando: false});
        });

    }

    cambiarValidacion = ({nombre, id, validado, observacion}) => {
        this.setState(state => {
            const obj = [];
            Object.assign(obj, state.validacion);
            obj[id] = {};
            obj[id].nombre = nombre;
            obj[id].idTipoDocumento = id;
            obj[id].archivoValido = validado;
            obj[id].observaciones = validado === 'N' ? observacion : undefined;

            if (validado)
                this.props.form.setFields({[nombre + 'Msj'] : {errors: []}})

            const todosValidos = !obj.some(valor => {
                return valor.archivoValido !== 'S';
            });

            return {validacion: obj, todosValidos};
        });
    }

    callbackCambio = () => {
        this.setState({activaGuardado: true});
    }

    callbackRegreso = () => {
        this.setState({activaGuardado: false});
    }
    
    render() {
        const ahora = moment();
        const datosAspirante = this.props.datosAspirante || {};
        const datosUsuario = this.props.datosUsuario || {};
        const listaSedes = this.listaSedes || [];
        const views = this.renderViews? this.renderViews() : {};
        const captura = rolesCaptura.some(rol => (datosUsuario.rolUsuario === rol))
        const aparece = datosAspirante.estatusRegistro !== 4 &&
                        (this.props.operaciones.accion === 'captura' || this.props.operaciones.accion === 'asignaSede') &&
                        captura;

        const aceptarTexto = this.props.operaciones.accion === 'captura' ? 'Guardar' : 'Asignar sede';
        const activaGuardado = this.props.operaciones.accion === 'asignaSede' || this.state.activaGuardado;
        const sinCede = this.props.form.getFieldValue('sinCede');
        const sinRadio = this.state.todosValidos || datosAspirante.estatusRegistro === 4 || !captura;

        const fecha = this.props.form.getFieldValue('fechaExamen');
        const sinFecha = !fecha;

        var horaMin;
        if (fecha) {
            const ahoraStr = ahora.format(etiquetas.FORMATO_FECHA_MOMENT);
            const fechaStr = fecha.format(etiquetas.FORMATO_FECHA_MOMENT);
            horaMin = ahoraStr === fechaStr ? ahora.format('HH:mm') : '08:00';
        }

        return (
            <AuthenticatedComponent externo={false}
                                    listo={this.listo}>
                <Template contenido={
                    <Layout className='validar_modulo'>
                        <Header theme='light' className='header_validar'>
                            <div>
                                <div>Documentación por validar</div>
                                <span>
                                    <span className='validar_titulo'>Clave de elector</span>
                                    <span className='validar_contenido'>{datosAspirante.claveElectorFuar}</span>
                                </span>
                                <span>
                                    <span className='validar_titulo'>Sección</span>
                                    <span className='validar_contenido'>{datosAspirante.seccionDom}</span>
                                </span>
                            </div>
                        </Header>
                        <Layout className='contenido_validar' onFocus={this.establecerIniciales}>
                            <Content className='contenido_anidado'>
                                <React.Fragment>
                                    <div className='encabezado_validar'>
                                        <span onClick={() => this.cambiarTab(0)} className={this.state.tab === 0 ? 'validar_activo' : ''}>
                                            Obligatoria
                                        </span>
                                        <span onClick={() => this.cambiarTab(1)} className={this.state.tab === 1 ? 'validar_activo' : ''}>
                                            Opcional
                                        </span>
                                    </div>
                                    <div className='visor_validar'>
                                        {this.state.cargado ? views.viewers : null}
                                    </div>
                                </React.Fragment>
                            </Content>
                            <Sider theme='light' width='30%'>
                                {this.state.cargado ? views.controlls : null}
                                {/*    <hr />
                                        <a href='#' onClick={(e) => this.toggleMostrar(e, 'mostrarCarta')}><Icon type='eye'/> Ver carta declaratoria</a>
                                <hr/>*/}
                                <Cubrimiento cubrir={this.props.operaciones.accion !== 'captura'}>
                                    <RadioGroup nombre='radioDecision'
                                                form={this.props.form}
                                                disabled={sinRadio}
                                                ref={this.radioDecisionRef}>
                                        <Radio value='true' onClick={(e) => this.manejarRadiosJDE('true', e)}>Acude a la junta</Radio>
                                        <Radio value='false' onClick={(e) => this.manejarRadiosJDE('false', e)}>Declinar solicitud</Radio>
                                        {datosAspirante.bAcudirJDE === 1 ?
                                            <Radio value='permite' onClick={(e) => this.manejarRadiosJDE('permite', e)}>Permitir registro</Radio>
                                            :
                                            null
                                        }
                                    </RadioGroup>
                                </Cubrimiento>
                            </Sider>
                        </Layout>
                        <Footer>
                            <Button type='primary' onClick={this.cancelarRevision}>{this.props.operaciones.accion === 'consulta' ? 'Volver' : 'Cancelar'}</Button>
                            {aparece ? (
                                <Popconfirm title={this.confirmacion}
                                            onConfirm={this.validarRevision}
                                            okText='Sí'
                                            cancelText='No'
                                            disabled={!activaGuardado}>
                                    <Button type='primary'
                                            disabled={!activaGuardado}>{aceptarTexto}</Button>
                                </Popconfirm>)
                                : null
                            }
                        </Footer>
                    </Layout>
                }
                guia={false}
                bloqueado={!this.props.datosAspirante}
                cargando={this.state.cargando}
                bloquearTodo={this.state.cargando}/>
                <Modal visible={this.state.mostrarCarta}
                       title='Carta declaratoria'
                       centered
                       style={{width: '600px'}}
                       onCancel={(e) => this.toggleMostrar(e, 'mostrarCarta')}
                       width={this.state.widthModal}
                       footer={null}>

                    <SourceViewer nombre='cartaDeclararoria'
                                  urlDoc={process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/carta_declaratoria'}
                                  type='application/pdf'
                                  recursos={{}}
                                  visible={() => true}
                                  onLoad={this.modificaModal}/>

                </Modal>

                <Modal visible={this.state.mostrarCuadroExamen && this.state.sedesCargadas}
                       title='Has validado todos los documentos del ciudadano'
                       onCancel={(e) => this.toggleMostrar(e, 'mostrarCuadroExamen')}
                       closable={false}
                       okText='Guardar'
                       onOk={() => this.guardarRevision(true)}
                       cancelText='Cancelar'
                       zIndex={900}
                       width={700}>
                    {this.props.operaciones.accion === 'captura' ? 
                        <Checkbox nombre='sinCede'
                            form={this.props.form}
                            required={false}
                            rules={[{type: 'boolean'}]}
                            onChange={this.cambioAsignarSede}>
                            <b>Asignar sede en otro momento</b>
                        </Checkbox>
                        : null
                    }

                    <b className={sinCede ? 'inactivo' : ''}>Asigna los datos para presentación del examen:</b>

                    {this.state.sedesCargadas ? (
                        <Select nombre='sede'
                                placeholder='Sede'
                                required={!sinCede}
                                form={this.props.form}
                                showSearch={true}
                                disabled={sinCede}>
                            {listaSedes.map(valor => {
                                return (
                                <Option key={valor.id.idSede + 'Sede'} value={valor.id.idSede}>
                                    {'COL. ' + valor.colonia + ' CP. ' + valor.cp + ' DIR. ' + valor.domicilioSede + ', LUGAR: ' + valor.lugar}
                                </Option>); 
                            })}
                        </Select>)
                        : null}

                    <Row type='flex' justify='space-between'>
                        <Col span={11}>
                            <Calendario nombre='fechaExamen'
                                        label='Fecha'
                                        required={!sinCede}
                                        format={etiquetas.FORMATO_FECHA_MOMENT}
                                        formato="dd/mm/aaaa"
                                        showTime={false}
                                        showToday={true}
                                        form={this.props.form}
                                        minDate={moment().format(etiquetas.FORMATO_FECHA_MOMENT)}
                                        disabled={sinCede}/>
                        </Col>
                        <Col span={11}>
                            <Tiempo nombre='horaExamen'
                                    label='Hora'
                                    format="HH:mm"
                                    formato="HH:mm" 
                                    required={!sinCede}
                                    form={this.props.form}
                                    minTime={horaMin}
                                    maxTime={'19:59'}
                                    disabled={sinCede || sinFecha}/>
                            <label id='aviso_JDE'>El aspirante deberá acudir dos horas antes de la presentación del examen</label>
                        </Col>
                    </Row>
                </Modal>
            </AuthenticatedComponent>
        );
    }
}

class CuadroValidacion extends Component {
    state = {validado: undefined,
             observacion: undefined,
             clase: 1}

    componentDidMount() {
        var validar;
        var rechazar;

        if (this.props.archivoValido) {
            validar = this.props.archivoValido === 'S';
            rechazar = !validar;
        }

        this.props.cambiarValidacion({
            nombre: this.props.nombre,
            id: this.props.id, 
            validado: this.props.archivoValido, 
            observacion: this.props.observacion
        });

        this.props.form.setFields({
            [this.props.nombre + 'Validar'] : {value: validar},
            [this.props.nombre + 'Rechazar'] : {value: rechazar},
            [this.props.nombre + 'Observacion'] : {value: this.props.observacion},
        });

        this.setState({validado: this.props.archivoValido});
    }

    handleChange = (nombre, val) => {
        const nombreRechazar = this.props.nombre + 'Rechazar';
        this.props.form.haValidado = false;

        if (val)
            this.props.form.setFields({[nombre]: {value: !val}});

        const validado =  val ? (nombre === nombreRechazar ? 'S' : 'N') : undefined;
        this.setState({validado});
        this.props.cambiarValidacion({nombre: this.props.nombre, id: this.props.id, validado, observacion: this.state.observacion});
    }

    handleInputChange = () => {
        const observacion = this.props.form.getFieldValue(this.props.nombre + 'Observacion');
        this.props.cambiarValidacion({nombre: this.props.nombre, id: this.props.id, validado: this.state.validado, observacion});
        this.setState({observacion});
    }

    componentDidUpdate() {
        if (this.state.clase === 2) {
            setTimeout(() => this.setState({clase: 1}), 0);
            this.props.scrollVisor(this.props.nombre);
        }
    }

    getFocus = () => {
        this.props.cambioFoco(this.props.nombre, () => {
            this.setState({clase: 2});
        });
    }

    render() {
        const nombreValidar = this.props.nombre + 'Validar';
        const nombreRechazar = this.props.nombre + 'Rechazar';
        const cN = this.state.clase === 1 ? 'cuadro_validacion' : 'cuadro_validacion cuadro_validacion_tomado';
        const vs = !this.props.visible() ? 'visor_hidden' : '';

        return (
            <div id={'id' + this.props.nombre}
                 className={cN + ' ' + vs}
                 onFocus={this.getFocus}
                 tabIndex={this.props.id}>
                <Cubrimiento cubrir={this.props.cubrir}>
                    <div className='cuadro_validacion_titulo'>{this.props.titulo}</div>
                    <div>
                        <InputText form={this.props.form}
                                nombre={this.props.nombre + 'Msj'}
                                elementClass='solo_error'/>
                        <Checkbox form={this.props.form}
                                nombre={nombreValidar}
                                required={false}
                                rules={[{type: 'boolean'}]}
                                onChange={e => {this.handleChange(nombreRechazar, e)}}>
                            {this.state.validado ? 'Validado' : 'Validar'}
                        </Checkbox>
                        <Checkbox form={this.props.form}
                                nombre={nombreRechazar}
                                required={false}
                                rules={[{type: 'boolean'}]}
                                onChange={e => {this.handleChange(nombreValidar, e)}}>
                            {this.state.validado === false ? 'Rechazado' : 'Rechazar'}
                        </Checkbox>
                    </div>
                    <div>
                        <TextArea nombre={this.props.nombre + 'Observacion'}
                                placeholder='Observaciones'
                                form={this.props.form}
                                disabled={!this.state.validado || this.state.validado === 'S'}
                                rules={[{max: 300}]}
                                required={this.state.validado === 'N'}
                                onBlur={this.handleInputChange}
                                autosize={{minRows: 5, maxRows: 5}}/>
                    </div>
                </Cubrimiento>
            </div>
        );
    }
}

const mapStateToProps = state => {
    var datosAspirante, datosUsuario;
    const operaciones = state.operaciones || {};

    if (state.sesion.datosUsuario) {
        datosUsuario = state.sesion.datosUsuario;
        datosAspirante = datosUsuario.datosAspirante;
    }

    return {
        datosUsuario: datosUsuario,
        datosAspirante: datosAspirante,
        operaciones
    };
}

export default Form.create({name: 'validacion', onFieldsChange: function onValuesChange() {
    _compararValores();
}})(connect(mapStateToProps, {implantarAspirante, emitirOperacion, borrarOperacion})(ValidacionDocumentos));
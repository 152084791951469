import {type as emitirMensaje} from '../actions/emitirMensaje';
import {terminoSesion} from '../actions/accionesSesion';

const defaultState = {mensajes: {}};

function reducer(state = defaultState, {type, payload}) {
	switch (type) {
		case terminoSesion:
			return {mensajes: {}};
		case emitirMensaje:
            const newState = Object.assign({}, state);
            newState.mensajes = Object.assign({}, state.mensajes);
            newState.mensajes[payload.id] = payload;

			return newState;
		default: 
			return state;
	}
}

export default reducer;
import React, { useState } from "react";
import { Input } from "antd";
import { Button } from "antd";
import { Row, Col, Icon,Popover } from "antd";
import ayudaClaveFUAR from '../../../img/ayudaBusqueda.jpg'
function FormBusquedaBandeja({ buscarAspirante }) {
  const [busqueda, agregarBusqueda] = useState({
    claveElector: ""
  });

  // Función para actualizar el state de los inputs
  const actualizarState = e => {
    agregarBusqueda({
      ...busqueda,
      [e.target.name]: e.target.value
    });
  };

  // Cuando hacemos submit al form
  const enviarInformacion = e => {
    e.preventDefault();
    buscarAspirante(busqueda);
  };

  return (
   /*  <div className="bg-info">
      <div className="container">
        <div className="row"> */
          <form
            onSubmit={enviarInformacion}
            className="col card text-white bg-transparent  mb-5 pt-5 pb-2"
          >
            <Row>
              <Col span={4}></Col>

              <Col span={14}>
                <Input
                  prefix={<Icon type='search'/>}
                  type="text"
                  className="form-control"
                  name="claveElector"
                  placeholder="Clave elector"
                  onChange={actualizarState}
                  required
                />
              </Col>
              <Col span={3}>
                <Button id="btnBusqueda" htmlType="submit" type="primary">
                  Buscar
                </Button>
              </Col>
              <Col span={1}>
              <Popover overlayClassName='pop_tooltip'
                                     disabled={false} 
                                content={<img src={ayudaClaveFUAR} width='500px'/>}>
								<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
							</Popover>
              </Col>
              <Col span={3}></Col>
            </Row>
          </form>
    /*     </div>
      </div>
    </div> */
  );
}
export default FormBusquedaBandeja;

import React from 'react'
import TemplateExterno from './interfaz/TemplateExterno'

export default function SinResultados() {
    return (
        <TemplateExterno enlaces={false}
            contenidoExpandido={true}>
            <div className="mensaje_container">
                <div>
                    <span className='mensaje_titulo'>
                        <span className='cara_triste'>
                            :(
                        </span>
                        Página no encontrada
                    </span>
                </div>
            </div>
        </TemplateExterno>);
}


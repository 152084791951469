import React from 'react';
import {Icon, Row} from 'antd';
import Template from '../interfaz/Template';
import {registrarTerminado} from '../../redux/actions/accionesSesion'
import {connect} from 'react-redux'
import { Link } from "react-router-dom";
class ExitoModifica extends React.Component {
    

    componentWillUnmount() {
        //this.props.registrarTerminado(false);
    }

    render() {
        return(
            <Template contenido={
                <div className="exito_container">
                    <div>
                        <span className='exito_titulo'><Icon type="smile" style={{fontSize: '1.5em'}} /> Los datos del aspirante se han actualizado correctamente</span>
                       {/*  <span className='exito_detalle'>En breve recibirás un correo electrónico para confirmar tu registro.</span> */}
                       <br></br>
                       <br></br>
                       <Row>
                       <center>
                        <Link to={`/bandeja`}className="btn btn-success mr-2" style={{fontSize: '1.5em'}}>
                            Regresar a bandeja
                        </Link>
                       </center>
                       </Row>
                      
                       
                    </div>
                </div>
            }
            guia={false}
            />
        );
    }
};

const mapStateToProps = state => {
    return {
        ventanaExito: state.sesion.exito
    }
}

const mapDispatchToProps = {
    registrarTerminado
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitoModifica);
import React from 'react';
import {Card, Row, Col} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import RadioGroup, {Radio} from '../interfaz/Radio';

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

class DatosOtros2 extends React.Component {

    constructor(props) {
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = { 
            
        };  
        
    }    

    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_datos_otros_lbl_titulo} bordered={false}>                    
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'serviciosFinSemFestivos'}
                                        name={'serviciosFinSemFestivos'} 
                                        label={etiquetas.datos_datos_otros_lbl_serviciosFinSemFestivos} 
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'actividadesCampo'}
                                        name={'actividadesCampo'} 
                                        label={etiquetas.datos_datos_otros_lbl_actividadesCampo} 
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'militantePartido'}
                                        name={'militantePartido'} 
                                        label={etiquetas.datos_datos_otros_lbl_militantePartido} 
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'representantePartido'} 
                                        name={'representantePartido'} 
                                        label={etiquetas.datos_datos_otros_lbl_representantePartido} 
                                        form={this.props.form}
                                        required={true} 
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'afinidadFamConsaguineo'} 
                                        name={'afinidadFamConsaguineo'}  
                                        label={etiquetas.datos_datos_otros_lbl_afinidadFamConsaguineo} 
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>                    
                </Card>
           </React.Fragment>                                            
        );          
    }
};
export default DatosOtros2;
import React, {Component} from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {terminarSesion, establecerSesion} from '../redux/actions/accionesSesion';
import Template from './interfaz/Template';
import TemplateExterno from './interfaz/TemplateExterno';

class AuthenticatedComponent extends Component {
	state = {cargado: false};

 	componentDidMount() {
 		const jwt = localStorage.getItem('jwt-token');

 		if (!jwt && this.props.privado) {
			 this.props.history.push('/acceso');
			 return;
		}
		else if (!jwt && !this.props.privado) {
			this.setState({cargado: true}, this.props.listo);
			return;
		}

		var busca = this.props.datosUsuario === undefined;

 		axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/recupera_datos', {
			 busca: busca,
		},
		{
			headers : {Authorization: jwt},
		}).then(res => {
			const pathname = this.props.history.location.pathname;
			var datos = this.props.datosUsuario;

			if (busca) {
				datos = res.data.entity.datosUsuario;
				localStorage.setItem('jwt-token', datos.tknJWT);

				this.props.establecerSesion({
					datosUsuario: datos,
				});

				if (datos.tipoUsuario === 'E') {
					if (datos.datosAspirante.estatusRegistro === 1){
						if (pathname !== '/cargaDocumentos')
							this.props.history.push('/cargaDocumentos');
					}
					else if(datos.datosAspirante.estatusRegistro === 2) {
						if (datos.datosAspirante.bObservacion) {
							if (pathname !== '/cargaDocumentos')
								this.props.history.push('/cargaDocumentos');
						}
						else if (typeof datos.datosAspirante.bObservacion !== "number" &&
							     datos.datosAspirante.bPlatica === 0) {
							if (pathname !== '/finalizado')
								this.props.history.push('/finalizado');
						}
						else if (pathname !== '/platica_induccion')
							this.props.history.push('/platica_induccion'); 
					}
					else if (datos.datosAspirante.estatusRegistro === 3) {
						if (pathname !== '/finalizado')
							this.props.history.push('/finalizado');
					}
					else if (datos.datosAspirante.estatusRegistro === 4) {
						if (pathname !== '/solicitud_declinada')
							this.props.history.push('/solicitud_declinada');
					}
					else
						this.props.history.push('/enConstruccion');
				}
			}
			else
				localStorage.setItem('jwt-token', res.data.entity);

			if (datos.tipoUsuario === 'E' && !this.props.externo) 
				this.props.history.push('/enConstruccion');
			else if (datos.tipoUsuario === 'I' && !this.props.privado) {
				if (pathname === '/loginSEyCAE' || pathname === '/acceso')
					this.props.history.push('/menuJDE');
			}

			const pathname2 = this.props.history.location.pathname;

			if (pathname === pathname2)
				this.setState({cargado: true}, this.props.listo);
	 	}).catch(err => {
			this.setState({cargado: true});

			if (err.response && err.response.data === 'error_token') {
				this.props.history.push('/acceso');
				localStorage.removeItem('jwt-token');
			}
		});
 	}

 	render() {
		if (!this.state.cargado && this.props.datosUsuario)
			return <Template guia={false} bloqueado={true}/>;
		else if (!this.state.cargado)
			return <TemplateExterno bloqueado={true} enlaces={false}/>;

 		return this.props.children;
 	}
 }

AuthenticatedComponent.defaultProps = {
	externo: true,
	privado: true,
}

const mapStateToProps = (state) => {
	return {
		datosUsuario: state.sesion.datosUsuario,
	};
};

const mapDispatchToProps = {
	establecerSesion,
	terminarSesion,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticatedComponent));
import React, {Component} from 'react';
import {Select as _Select} from 'antd';
import {configuraComponente, configuraCompInterno} from '../../utils/funciones';
export const { Option } = _Select;

class Select extends Component {
	constructor (props) {
		super(props);
		configuraComponente.bind(this)();
        this.custom.trigger = '';
	}
    
    shouldComponentUpdate = (nextProps) => {
        if (this.props.showSearch !== nextProps.showSearch)
            return true;

        if (this.props.children !== nextProps.children)
            return true;

        return false;
    }

    render () {
        this.customRules.type = 'number';

        const input = <SelectI showSearch={this.props.showSearch}
            optionFilterProp="children"
            mode={this.props.mode}
            _onChange={this.onChange}
            onFocus={this.props.onFocus}
            onBlur={this.onBlurAndChange}
            onSearch={this.props.onSearch}
            disabled={this.props.disabled}>
            {this.props.children}
        </SelectI>;

        return this.funcComunComponent(input, 'select-container');
    }
}

class SelectI extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
    }
    
    render() {
		return (
            <_Select {...this.props}
                value={this.state.valor}
                notFoundContent='Sin resultados'
                onChange={this.onChange}>
                {this.props.children}
            </_Select>
		);
	}
}

export default Select;
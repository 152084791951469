import React, {Component} from 'react'
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment'
import 'moment/locale/es';
import {configuraComponente} from '../../utils/funciones';

const regexp = /HH|mm|ss/;

class Calendario extends Component {
	state = {
		minDate: undefined,
		maxDate: undefined,
	}

	constructor(props) {
		super(props);
		configuraComponente.bind(this)();
		this.custom.trigger = 'onChange';
	}

	setMinMaxDate = () => {
		if (this.props.format) {
			if (this.props.minDate) {
				const minDate = moment(this.props.minDate, this.props.format);
				this.setState({minDate});
			}
				
			if (this.props.maxDate) { 
				const maxDate = moment(this.props.maxDate, this.props.format);
				if (maxDate.hours() === 0 &&
					maxDate.minutes() === 0 &&
					maxDate.seconds() === 0 &&
					!this.props.format.match(regexp) !== null)
					maxDate.add(86399, 'seconds');

				this.setState({maxDate});
			}
		}
	}

	componentDidMount() {
		this.setMinMaxDate();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.minDate !== this.props.minDate ||
			prevProps.maxDate !== this.props.maxDate)
			this.setMinMaxDate();
	}

	disabledDate = (value) => {
		if (this.state.minDate !== undefined && this.state.minDate > value)
			return true;

		return this.state.maxDate !== undefined && this.state.maxDate < value;
	}

	render () {
		const format = this.props.format || 'DD/MM/YYYY';

        this.customRules.type = 'object';
        this.customRules.initialValue = moment();

        const input = <DatePicker showTime={this.props.showTime}
								  format={format}
								  locale={locale}
                                  disabled={this.props.disabled}
                                  onChange={this.onChange}
								  disabledDate={this.disabledDate}
                                  placeholder={this.props.formato}/>
		
		return this.funcComunComponent(input, 'calendar-container', false);
	}
}

export default Calendario;
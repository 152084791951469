import React from 'react'
import '../../css/progreso_registro.css';

function ProgresoRegistro(props) {
    return (
        <div className='progreso_registro'>
            <span className='progreso' style={{width: props.porcentaje + '%'}}></span>
        </div>
    );
}

export default ProgresoRegistro;
import React from 'react';
import {Card, Row, Col} from 'antd';
import * as etiquetas from '../../ApplicationResources';
import RadioGroup, {Radio} from '../interfaz/Radio';
import InputText from '../interfaz/InputText';

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

class DatosOtros3 extends React.Component {

    constructor(props) {
        // Calling the constructor of  
        // Parent Class React.Component 
        super(props);  
          
        // Setting the initial state 
        this.state = {             
            
        };  
       
        this.handleHablaLenguaIndigenaChange = this.handleHablaLenguaIndigenaChange.bind(this);
        
    }   

    handleHablaLenguaIndigenaChange = (value) => {  
        this.props.form.setFieldsValue(
                {
                    cualLenguaIndigena: null,
                },
                () => {
                    this.props.form.validateFields(['cualLenguaIndigena'], { force: true });
                }
            );
    }

    render() {

        return(
           <React.Fragment>
                <Card title={etiquetas.datos_datos_otros_lbl_titulo} bordered={false}>                                        
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'experienciaManejoGrupos'}
                                        name={'experienciaManejoGrupos'}
                                        label={etiquetas.datos_datos_otros_lbl_experienciaManejoGrupos} 
                                        form={this.props.form}
                                        required={true} 
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'impartirCapacitacion'} 
                                        name={'impartirCapacitacion'} 
                                        label={etiquetas.datos_datos_otros_lbl_impartirCapacitacion} 
                                        form={this.props.form}
                                        required={true} 
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'hablaLenguaIndigena'} 
                                        name={'hablaLenguaIndigena'} 
                                        label={etiquetas.datos_datos_otros_lbl_hablaLenguaIndigena} 
                                        form={this.props.form}
                                        required={true} 
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}
                                        onChange={(value) => this.handleHablaLenguaIndigenaChange(value)}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <InputText nombre="cualLenguaIndigena"
                                        placeholder={etiquetas.datos_datos_otros_lbl_cualLenguaIndigena}                                                                              
                                        form={this.props.form}
                                        disabled={!(this.props.form.getFieldValue("hablaLenguaIndigena") === 'S')}                                                  
                                        required={this.props.form.getFieldValue("hablaLenguaIndigena") === 'S'}
                                        msjRequerido={etiquetas.general_dato_requerido}
                                        maxLength={50}
                                        rules={[{
                                                max: 50
                                                , pattern: etiquetas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES 
                                            }]}/>                            
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'sabeManejar'} 
                                        name={'sabeManejar'} 
                                        label={etiquetas.datos_datos_otros_lbl_sabeManejar} 
                                        form={this.props.form}
                                        required={true} 
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={24} md={24} lg={24}>
                            <RadioGroup nombre={'tieneLicenciaManejo'}
                                        name={'tieneLicenciaManejo'}
                                        label={etiquetas.datos_datos_otros_lbl_tieneLicenciaManejo}
                                        form={this.props.form}
                                        required={true}
                                        msjRequerido={etiquetas.general_dato_requerido}                                        
                                        rules={[{
                                                type: "enum"
                                                , enum: ['S', 'N']
                                            }]}>
                                {
                                    siNoOptions &&
                                    siNoOptions.map((opcion, index) => (
                                        <Radio key={index} value={opcion.value}>{opcion.label}</Radio>
                                    ))
                                }
                            </RadioGroup>
                        </Col>
                    </Row>                    
                </Card>
           </React.Fragment>                                            
        );          
    }
};
export default DatosOtros3;
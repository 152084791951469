import React, {Component} from 'react'
import {Checkbox as _Checkbox} from 'antd'
import {configuraComponente, configuraCompInterno} from '../../utils/funciones';

class CheckboxC extends Component {
	constructor (props) {
		super(props);
        configuraComponente.bind(this)();
        this.valueProp = 'checked'
    }
    
    render () {
        const star = this.props.required ? <span className='star'>*</span> : null;
        this.customRules.type = 'boolean';
        this.custom.trigger = 'onChange';
        
        const input = (<div>
                            <Checkbox required={this.props.required}
                                style={this.props.style}
                                _onChange={this.onChange}
                                value={this.props.form.getFieldValue(this.props.nombre)}
                                disabled={this.props.disabled}>
                                {star}{this.props.children}
                            </Checkbox>
                       </div>);

        return this.funcComunComponent(input, 'check-container', false);
    }
}

CheckboxC.Group = class CheckboxGroup extends Component {
	constructor (props) {
		super(props);
		configuraComponente.bind(this)();
        this.custom.trigger = 'onChange';
    }

    render () {
        const input = (<_Checkbox.Group onChange={this.onChange} 
                            style={this.props.style}
                            nombre={this.props.nombre}
                            disabled={this.props.disabled}
                            name={this.props.name}
                            options={this.props.options}>
                            {this.props.children}
                        </_Checkbox.Group>);

        return this.funcComunComponent(input, 'check-group-container', false);
    }
}

class Checkbox extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
        this.valueProp = 'checked'
	}

	render() {
		return (
            <_Checkbox {...this.props}
                checked={this.state.valor}
                onChange={this.onChange}>
                {this.props.children}
            </_Checkbox>
		);
	}
}

class GroupI extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
    }
    
    cambios = (e) => {
        const div = document.getElementsByClassName(this.props.nombre + 'Div')[0];
        div.value = e;
        div.attributes.value = e;

        this.onChange(e);
    }

    render() {
		return (
            <_Checkbox.Group {...this.props}
                value={this.state.valor}
                onChange={this.cambios}>
                {this.props.children}
            </_Checkbox.Group>
		);
	}
}

export default CheckboxC;
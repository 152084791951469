import React, {Component} from 'react'
import {Icon, Button} from 'antd';
import Template from './interfaz/Template';
import axios from 'axios';
import {cambiarEstatus} from '../redux/actions/accionesSesion'
import {connect} from 'react-redux'

export class ValidaCorreo extends Component{

    state = {
        cargado: false,
        folioAcceso: undefined,
        claveElectorFuar: undefined,
    } 
    
    componentDidMount(){
        const v = window.location.search.slice(3);
        
        axios.post(process.env.REACT_APP_API_URL + '/centralReclutaSEyCAE/ws/validaAspirante', {
            valor: v, 
        }
        ).then(res => {
            const entidad = res.data.entity;

            this.setState({folioAcceso: entidad.data.folioAcceso,
                           claveElectorFuar: entidad.data.claveElectorFuar,
                           cargado: true});
            this.props.cambiarEstatus(1);
        });
    }

    render(){
        return(
            <Template contenido={  
                <div className="exito_container">
                    <div>
                        <span className='exito_titulo'><span className='cara_feliz'>:)</span> ¡Tu correo electrónico fue validado!</span>
                        <div className='exito_informacion'>
                            <div>Ahora, inicia sesión con el usuario y contraseña proporcionado para continuar con el proceso</div>
                            <div className='usu_contr'>
                                <div><span>Usuario: </span>{this.state.folioAcceso}</div>
                                <div><span>Contraseña: </span>{this.state.claveElectorFuar}</div>
                            </div>
                            <div>Recibirás tu Acuse de validación por correo electrónico</div>
                        </div>
						<span className='exito_detalle'>Revisa en tu bandeja de entrada o en tus correos no deseados.</span>
                        <span id='inicia_sesion'><Button onClick={() => this.props.history.push('/acceso')}>Inicia sesión</Button></span>
                    </div>
                </div>
            }
            bloqueado={!this.state.cargado}/>
        )
    } 
}

export default connect((_state) => {return {}}, {cambiarEstatus})(ValidaCorreo);

import React, { Fragment }  from 'react';
import  { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Link } from "react-router-dom";
import AuthenticatedComponent from './../../AuthenticatedComponent';
import Aspirante from './../../aspirante/AspiranteComponent.js';
import Template from './../../interfaz/Template.js';
import { async } from 'q';
import moment from 'moment'
import 'moment/locale/es';
import * as etiquetas from '../../../ApplicationResources';
import {  useSelector } from "react-redux";
function ModificaAspirante({folio,claveElector, props}) {
        const funcionListo = () => {
                if (!datosAspirante) this.props.history.goBack();
                sesionData(true);
               
                buscarAspiranteModificar();
              };
        const [datosModifica, establecerAspiranteModifica] = useState({
                objetos:  [{},{},{}]
        });
        const [datosSesion, sesionData] = useState(false);
        const [mostrar, mostrarComponente] = useState(false)
        const [fuar, setearFuar] = useState(true);  //     
        const datosAspirante = useSelector(state => state);
        const buscarAspiranteModificar = async  () => {
                const { idProcesoElectoral, idDetalle, idEstado,  idDistrito } = datosAspirante.sesion.datosUsuario;
                var da = datosAspirante;
                console.log("inicio se seteo los objetos en aspirante")  
                // Obtenido los datos del servidor
               const folioAspirante = parseInt(folio);
               
                var postData = {
                    idProceso: idProcesoElectoral,
                    idDetalle: idDetalle,
                    idEstado: idEstado,
                    idDistrito: idDistrito,
                    claveElectorFUAR:  claveElector,
                    folio: folioAspirante
                  };
                  const jwt = localStorage.getItem("jwt-token");
                  await axios.post(process.env.REACT_APP_API_URL+
                    "/centralReclutaSEyCAE/ws/buscarAspiranteModificar",
                    postData,
                    { headers: { Authorization: jwt } }
                  ).then(response => {
                    if(response.data !== ""){
                                
                        var esClaveElectorFuar = etiquetas.REGEX_FORMATO_SOLO_DIGITOS.test(response.data.claveElectorFuar);
                        if(esClaveElectorFuar === true){
                                setearFuar(false);   //habilita campo para edicion clave elector fuar
                        }else{
                                setearFuar(true);    //deshabilita campo para edicion clave elector fuar      
                        }
                        establecerAspiranteModifica(
                        {
                                objetos:  [
                                        {
                                                "avisoPrivacidad":{"value": true},
                                                "claveElectorFuar":     {"value":response.data.claveElectorFuar},
                                                "idEstado":{"value":response.data.idEstado},
                                                "seccion":{"value":response.data.seccion+""},
                                                "idProcesoElectoral": response.data.idProcesoElectoral,
                                                "idDetalleProceso": response.data.idDetalleProceso,
                                                "idParticipacion": response.data.idParticipacion,
                                                "idConvocatoria": response.data.idConvocatoria,
                                                "idAspirante": response.data.idAspirante,
                                                "folioPrincipal":response.data.folioPrincipal,
                                                "folioAcceso":response.data.folioAcceso
                                         },{
                                                "fechaNacimiento": {"value":moment(response.data.fechaNacimiento, "YYYY-MM-DD")},
                                                "edad":{"value":moment().diff(moment(response.data.fechaNacimiento, "YYYY-MM-DD"), 'years') },
                                                "apellidoPaterno": {"value": response.data.apellidoPaterno},
                                                "apellidoMaterno": {"value": response.data.apellidoMaterno},
                                                "nombre": {"value": response.data.nombre}, 
                                                "curp": {"value": response.data.curp},  
                                                "sexo":{"value": response.data.sexo},  
                                                "correoElectronico": {"value": response.data.correoElectronico},
                                                "confirmaCorreoElectronico": {"value": response.data.correoElectronico},
                                                "rfc": {"value": response.data.rfc},
                                                "aceptaContactoCorreo": {"value": response.data.correoElectronico !=null?true:false},
                                                "lugarNacimiento": {"value": response.data.lugarNacimiento},
                                         },
                                         {
                                                "idMunicipio": {"value": response.data.idMunicipio},
                                                "idLocalidad": {"value": response.data.idLocalidad},
                                                "calleNumero": {"value": response.data.calleNumero},
                                                "colonia":  {"value": response.data.colonia},
                                                "cp": {"value": response.data.cp+""} ,
                                                "telefono": {"value": response.data.telefono} ,
                                                "celular": {"value": response.data.celular},       
                                         },
                                         {
                                                 "carrera": {"value":response.data.carrera},
                                                 "estudioActual": {"value":response.data.estudioActual},
                                                 "idEscolaridad": {"value":response.data.idEscolaridad},
                                                 "institucion": {"value":response.data.institucion},
                                         },
                                         {
                                                 "especifiqueMedio": {"value":response.data.especifiqueMedio},
                                                 "mediosSeleccionados": {"value":response.data.mediosSeleccionados},
                                                 "motivoParticipar": {"value":response.data.motivoParticipar},
                                         },
                                         {
                                                "fechaFinEmpresa1": {"value":response.data.fechaFinEmpresa1 !== null ? moment(response.data.fechaFinEmpresa1):null},
                                                "fechaFinEmpresa2": {"value":response.data.fechaFinEmpresa2 !== null ? moment(response.data.fechaFinEmpresa2):null},     
                                                "fechaFinEmpresa3": {"value":response.data.fechaFinEmpresa3 !== null ?moment(response.data.fechaFinEmpresa3): null},   
                                                "fechaInicioEmpresa1": {"value":response.data.fechaInicioEmpresa1 !== null ? moment(response.data.fechaInicioEmpresa1):null},
                                                "fechaInicioEmpresa2": {"value":response.data.fechaInicioEmpresa2 !== null ?moment(response.data.fechaInicioEmpresa2):null},     
                                                "fechaInicioEmpresa3": {"value":response.data.fechaInicioEmpresa3 !== null ?moment(response.data.fechaInicioEmpresa3):null},
                                                 "nombreEmpresa1": {"value":response.data.nombreEmpresa1},      
                                                 "nombreEmpresa2": {"value":response.data.nombreEmpresa2},    
                                                 "nombreEmpresa3": {"value":response.data.nombreEmpresa3},   
                                                 "puestoEmpresa1": {"value":response.data.puestoEmpresa1},
                                                 "puestoEmpresa2": {"value":response.data.puestoEmpresa2}, 
                                                 "puestoEmpresa3": {"value":response.data.puestoEmpresa3},
                                                 "telefonoEmpresa1": {"value":response.data.telefonoEmpresa1},
                                                 "telefonoEmpresa2": {"value":response.data.telefonoEmpresa2}, 
                                                 "telefonoEmpresa3": {"value":response.data.telefonoEmpresa3},     
                                         },
                                         {
                                                 "cualProceso":{"value":response.data.cualProceso},
                                                 "diponibilidadTiempo":{"value":response.data.diponibilidadTiempo},
                                                 "formasSeleccionadas":{"value":response.data.formasSeleccionadas},
                                                 "participoOtroEspecifique":{"value":response.data.participoOtroEspecifique},
                                                 "participoProceso":{"value":response.data.participoProceso}
                                         },
                                         {
                                                 "actividadesCampo":{"value":response.data.actividadesCampo},
                                                 "afinidadFamConsaguineo":{"value":response.data.afinidadFamConsaguineo},
                                                 "militantePartido":{"value":response.data.militantePartido},
                                                 "representantePartido":{"value":response.data.representantePartido},
                                                 "serviciosFinSemFestivos":{"value":response.data.serviciosFinSemFestivos}       
                                         },
                                         {
                                                "cualLenguaIndigena":{"value":response.data.cualLenguaIndigena},
                                                "experienciaManejoGrupos":{"value":response.data.experienciaManejoGrupos},
                                                "hablaLenguaIndigena":{"value":response.data.hablaLenguaIndigena},
                                                "impartirCapacitacion":{"value":response.data.impartirCapacitacion},
                                                "sabeManejar":{"value":response.data.sabeManejar},
                                                "tieneLicenciaManejo":{"value":response.data.tieneLicenciaManejo}                             
                                         },
                                         {
                                                 "companiaCelular":{"value":response.data.companiaCelular},
                                                 "marcaModelo":{"value":response.data.marcaModelo},
                                                 "tieneCelular":{"value":response.data.tieneCelular},
                                                 "utilizarCelular":{"value":response.data.utilizarCelular},
                                                 "utilizarVehiculo":{"value":response.data.utilizarVehiculo},
                                                 "vehiculoPropio":{"value":response.data.vehiculoPropio}
                                         },
                                         {
                                                 "cartaDeclaratoria":{"value":true},
                                                 "especifiqueDiscapacidad":{"value":response.data.especifiqueDiscapacidad},
                                                 "tiempoTraslado":{"value":response.data.tiempoTraslado !== null? moment(response.data.tiempoTraslado, "HH:mm:SS"):null},
                                                 "discapacidadesSeleccionadas":{"value":response.data.discapacidadesSeleccionadas},
                                                 "tieneInternet":{"value":response.data.tieneInternet}
                                         }
                                        ]
                        
                        }     
                        )
                        mostrarComponente(true);

                    } else { 
                        this.callbackResponse({0: {seccion: {error: "No existe convocatoria activa en la sección."}}});
                    }
                }).catch(error => {
                    if (error.response && error.response.status === 409){
                    } else {
                    }
                }) 
              };


              useEffect(() => { 
                if (datosSesion === true) 
                        buscarAspiranteModificar() 
                }, []);
	return (
        <AuthenticatedComponent listo={funcionListo}> 
                {mostrar === true?
                        <Aspirante  objetos={datosModifica.objetos} aspiranteCosulta={
                                {  folio: folio,
                                   claveElector: claveElector,
                                   fuar: fuar
                               }
                              
                        }  modifica={true} fuar={fuar}/> 
                        /* <span>componente cargado
                                
                        </span> */
                        :
                        <span>CARGANDO ESPERA
                                
                        </span>
                
                }      
        </AuthenticatedComponent>
		
	)
}

export default withRouter(ModificaAspirante);